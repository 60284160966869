import React, { FC } from 'react'
import config from '../../../../app/config/config'
import { makeStyles, Typography, useTheme } from '@material-ui/core'
import { BasicButton } from '../../../../app/components/button/button'
import { ABOUT_PAGE } from '../../../../app/utils/constants'
import { AppStoreSvg, GooglePlaySvg } from '../../../../app/assets/icons'

export const DownloadBlock: FC = () => {
  const classes = useStyles()
  const theme = useTheme()

  return (
    <div className={classes.downloadBlock}>
      <Typography variant={'h2'}>{ABOUT_PAGE.downloadApp}</Typography>
      <div className={classes.btnGroup}>
        <BasicButton
          className={classes.btn}
          component={'a'}
          variant={'outlined'}
          title={'AppStore'}
          startIcon={<AppStoreSvg />}
          color={theme.palette.text.accent}
          href={config.appleAppUrl}
          target={'_blank'}
        />
        <BasicButton
          className={classes.btn}
          component={'a'}
          variant={'outlined'}
          title={'GooglePlay'}
          startIcon={<GooglePlaySvg />}
          color={theme.palette.text.accent}
          href={config.googleAppUrl}
          target={'_blank'}
        />
      </div>
    </div>
  )
}

const useStyles = makeStyles(() => ({
  downloadBlock: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    marginBottom: 24,
    borderRadius: 5,
    width: '358px',
    height: '120px',
    '& h2': {
      color: '#111111',
      textAlign: 'center',
      marginBottom: 24,
    },
  },
  btnGroup: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
    width: '100%',
  },
  btn: {
    width: 171,
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '20px',
  },
}))
