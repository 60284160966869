import React from 'react'
import { CircularProgress, makeStyles } from '@material-ui/core'

export const PageLoader = () => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <CircularProgress size={60} color='inherit' />
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0 auto',
    maxWidth: 390,
    height: '100vh',
    color: theme.palette.pink.main,
  },
}))
