import React, { FC } from 'react'
import { CircularProgress, createStyles, makeStyles } from '@material-ui/core'

export interface ILoader {
  size?: number | string
  position?: string
}

const Loader: FC<ILoader> = (props) => {
  const classes = useStyles()

  return (
    <CircularProgress
      {...props}
      className={props.position || classes.customLoader}
      style={{ color: 'rgb(239, 202, 255)' }}
    />
  )
}

export default Loader

const useStyles = makeStyles(() =>
  createStyles({
    customLoader: {
      position: 'absolute',
      top: '50%',
      left: '60%',
    },
  }),
)
