import platform from 'platform'
import { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import { useNavigate } from 'react-router-dom'
import { AuthError } from 'firebase/auth'
import { LoginForm } from './SignInForm'
import { Helper } from './Helper'
import { To } from '../../app/router/to'
import { LOGIN_PAGE } from '../../app/utils/constants'
import { useAuth } from '../auth/AuthProvider'
import { errorMessage } from '../../app/utils/rest'
import { PageLoader } from 'app/components/PageLoader'
import { useAuthRedirect } from 'features/auth/hooks/useAuthRedirect'

export const SignInPage = () => {
  const classes = useStyles()
  const navigate = useNavigate()

  // Fix https://github.com/firebase/firebase-js-sdk/issues/6831
  const usePopupSignIn = platform.os?.family === 'iOS'

  const { isSignedIn, signInWithPopup, signInWithRedirect, getRedirectResult } = useAuth()

  const [isLoading, setLoading] = useState(() => !!localStorage.getItem('redirectUrl'))
  const [error, setError] = useState('')

  const pathname = useAuthRedirect(To.rate)

  const handleSignInSuccess = () => {
    navigate(pathname)
  }

  const signInWithGoogle = () => {
    localStorage.setItem('redirectUrl', pathname)
    if (usePopupSignIn) {
      signInWithPopup({ provider: 'google' })
        .then((result) => {
          result && setLoading(true)
        })
        .catch((error: AuthError) => {
          handleSignInError(error)
          localStorage.removeItem('redirectUrl')
        })
    } else {
      signInWithRedirect({ provider: 'google' })
    }
  }

  const signInWithApple = () => {
    localStorage.setItem('redirectUrl', pathname)
    if (usePopupSignIn) {
      signInWithPopup({ provider: 'apple' })
        .then((result) => {
          result && setLoading(true)
        })
        .catch((error: AuthError) => {
          handleSignInError(error)
          localStorage.removeItem('redirectUrl')
        })
    } else {
      signInWithRedirect({ provider: 'apple' })
    }
  }

  const handleSignInError = (error: AuthError) => {
    setError(errorMessage(error))
  }

  useEffect(() => {
    const redirect = localStorage.getItem('redirectUrl')

    if (!isSignedIn || !redirect) return

    if (!usePopupSignIn) {
      getRedirectResult()
        .then((result) => {
          result && navigate(redirect)
        })
        .catch((error: AuthError) => {
          handleSignInError(error)
        })
        .finally(() => {
          setLoading(false)
          localStorage.removeItem('redirectUrl')
        })
    }

    if (usePopupSignIn) {
      setLoading(false)
      navigate(redirect)
      localStorage.removeItem('redirectUrl')
    }
  }, [getRedirectResult, isSignedIn, navigate, pathname, usePopupSignIn])

  if (isLoading) {
    return <PageLoader />
  }

  return (
    <div className={classes.login}>
      <Typography className={classes.header} variant={'h1'}>
        {LOGIN_PAGE.header}
      </Typography>
      <Typography className={classes.helperText}>{LOGIN_PAGE.helperText}</Typography>
      <LoginForm
        error={error}
        onSignInError={handleSignInError}
        onSignInSuccess={handleSignInSuccess}
        onSignInWithGoogle={signInWithGoogle}
        onSignInWithApple={signInWithApple}
      />
      <Helper className={classes.helper} />
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  login: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    maxWidth: 390,
    margin: '0 auto',
    padding: theme.spacing(5, 2),
  },
  header: {
    marginBottom: 8,
  },
  helperText: {
    marginBottom: theme.spacing(5),
    lineHeight: '16px',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '12px',
    color: '#0D0E0F',
    opacity: 0.7,
  },
  helper: {
    marginTop: theme.spacing(8),
  },
}))
