import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import { To } from 'app/router/to'
import { MainPage } from '../../features/mainPage/MainPage'
import { SignInPage } from '../../features/signIn/SignInPage'
import { SignUpPage } from '../../features/signUp/SignUpPage'
import { AuthGuard } from '../../features/auth/AuthGuard'
import { RatePage } from '../../features/rate/RatePage'
import { PaymentFail } from '../../features/payment/PaymentFail'
import { PaymentSuccess } from '../../features/payment/PaymentSuccess'
import { PasswordRecoveryPage } from '../../features/restorePassword/PasswordRecoveryPage'
import { PasswordRecoveryInfoPage } from '../../features/restorePassword/PasswordRecoveryInfoPage'
import { PublicOfferPage } from 'features/publicOffer/PublicOfferPage'
import { PaymentAgreementPage } from 'features/paymentAgreement/PaymentAgreementPage'
import { createStyles, makeStyles } from '@material-ui/core'

export const MainRouter = () => {
  const classes = useStyles()

  return (
    <Router>
      <div className={classes.root}>
        <div className={classes.inner}>
          <Routes>
            <Route path={To.main} element={<MainPage />} />
            <Route path={To.signin} element={<SignInPage />} />
            <Route path={To.signup} element={<SignUpPage />} />
            <Route path={To.passwordRecovery} element={<PasswordRecoveryPage />} />
            <Route path={To.passwordRecoveryInfo} element={<PasswordRecoveryInfoPage />} />
            <Route path={To.publicOffer} element={<PublicOfferPage />} />
            <Route path={To.paymentAgreement} element={<PaymentAgreementPage />} />
            <Route element={<AuthGuard />}>
              <Route path={To.rate} element={<RatePage />} />
              <Route path={To.paymentFail} element={<PaymentFail />} />
              <Route path={To.paymentSuccess} element={<PaymentSuccess />} />
            </Route>
            <Route path='*' element={<MainPage />} />
          </Routes>
        </div>
      </div>
    </Router>
  )
}

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'center',
      width: '100vw',
      minHeight: '100vh',
    },
    inner: {
      width: 'min(390px, 100%)',
    },
  }),
)
