import axios, { AxiosError } from 'axios'
import { FirebaseError } from 'firebase/app'
import { RestError } from '../types/rest'
import { ERRORS } from './constants'

export const errorMessage = (error: AxiosError<RestError> | FirebaseError, defaultMessage = '') => {
  if (!axios.isAxiosError(error)) {
    return ERRORS[error.code] ?? error.message ?? defaultMessage
  }

  if (!error.response) return defaultMessage

  const { statusCode } = error.response.data.error
  return ERRORS[statusCode] ?? error.message ?? defaultMessage
}
