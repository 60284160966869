import { useMutation } from '@tanstack/react-query'
import { AuthError } from 'firebase/auth'
import { FirstParameter, Unwrap } from '../../../app/types/common'
import { useAuth } from '../AuthProvider'

type Auth = ReturnType<typeof useAuth>
type Response = Unwrap<ReturnType<Auth['sendPasswordResetEmail']>>
type Error = AuthError
type Variables = FirstParameter<Auth['sendPasswordResetEmail']>

export const usePasswordRecoveryMutation = () => {
  const { sendPasswordResetEmail } = useAuth()
  return useMutation<Response, Error, Variables>(sendPasswordResetEmail)
}
