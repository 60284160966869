import { FC, useState } from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import { Formik, Form, FormikErrors } from 'formik'
import { useNavigate } from 'react-router-dom'
import { AuthError } from 'firebase/auth'
import { FormButton } from '../../app/components/formik/button'
import { FormInput } from '../../app/components/formik/input'
import { validateEmail } from '../../app/utils/validate'
import { BasicCheckbox } from '../../app/components/checkbox/checkbox'
import { To } from '../../app/router/to'
import { BUTTON, REGISTRATION_PAGE } from '../../app/utils/constants'
import { ErrorBox } from '../../app/components/error/ErrorBox'
import { errorMessage } from '../../app/utils/rest'
import { useSignUpMutation } from '../auth/hooks/useSignUpMutation '
import { useAuthRedirect } from 'features/auth/hooks/useAuthRedirect'

type FormValues = {
  email: string
  password: string
  repeatPassword: string
}

const initialValues: FormValues = { email: '', password: '', repeatPassword: '' }

const validate = (values: FormValues) => {
  const errors: FormikErrors<FormValues> = {}

  if (!values.email) {
    errors.email = 'Required'
  } else if (validateEmail(values.email)) {
    errors.email = 'Invalid email'
  }

  if (!values.password) {
    errors.password = 'Required'
  }

  if (!values.repeatPassword) {
    errors.repeatPassword = 'Required'
  }

  if (values.password !== values.repeatPassword) {
    errors.repeatPassword = 'Passwords do not match'
  }

  return errors
}

export const SignUpPage: FC = () => {
  const classes = useStyles()
  const theme = useTheme()
  const navigate = useNavigate()

  const signUpMutation = useSignUpMutation()

  const [isChecked, setChecked] = useState<boolean>(false)
  const [error, serError] = useState('')

  const redirectPathname = useAuthRedirect(To.rate)

  const handleToggleChecked = () => {
    setChecked((prev) => !prev)
  }

  const handleSubmitError = (error: AuthError) => {
    serError(errorMessage(error))
  }

  const handleSubmitSuccess = () => {
    navigate(redirectPathname)
  }

  const handleSubmit = async (values: FormValues) => {
    await signUpMutation.mutateAsync(values, {
      onError: handleSubmitError,
      onSuccess: handleSubmitSuccess,
    })
  }

  return (
    <div className={classes.registration}>
      <Typography className={classes.header} variant={'h1'}>
        {REGISTRATION_PAGE.header}
      </Typography>
      <Formik initialValues={initialValues} validate={validate} onSubmit={handleSubmit}>
        <Form className={classes.form}>
          <ErrorBox className={classes.error} open={Boolean(error)} error={error} />
          <FormInput type={'text'} id={'email'} label={'Введите ваш e-mail'} placeholder={'Введите ваш эл.адрес'} />
          <FormInput type={'password'} id={'password'} label={'Введите пароль'} placeholder={'Введите пароль'} />
          <FormInput
            type={'password'}
            id={'repeatPassword'}
            label={'Повторите пароль'}
            placeholder={'Повторите пароль'}
          />
          <FormButton
            title={BUTTON.signUp}
            type={'submit'}
            color={theme.palette.pink.main}
            textColor={theme.palette.pink.contrastText}
            disabled={!isChecked}
            withoutBorder
          />
        </Form>
      </Formik>
      <label className={classes.checkbox}>
        <BasicCheckbox checked={isChecked} onChange={handleToggleChecked} />
        <Typography className={classes.checkboxTitle}>{REGISTRATION_PAGE.checkboxText}</Typography>
      </label>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  registration: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    maxWidth: '390px',
    margin: '0 auto',
    padding: theme.spacing(5, 2, 2),
  },
  header: {
    marginBottom: 40,
  },
  form: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  checkbox: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 16,
  },
  checkboxTitle: {
    fontSize: '14px',
    lineHeight: '18px',
    color: '#0D0E0F',
    fontWeight: 400,
  },
  error: {
    marginBottom: theme.spacing(2),
  },
}))
