import React from 'react'
import cn from 'classnames'
import { FormControlLabel, FormControlLabelProps, makeStyles, Radio } from '@material-ui/core'

type Props = Omit<FormControlLabelProps, 'className' | 'control'> & {
  className?: string
}

export const RadioButton = ({ className, ...props }: Props) => {
  const classes = useStyles()
  return <FormControlLabel className={cn(classes.root, className)} {...props} control={<Radio disableRipple />} />
}

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiFormControlLabel-label': {
      width: '100%',
    },
  },
}))
