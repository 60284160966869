import { FC, useState } from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import { Formik, Form, FormikErrors } from 'formik'
import { useNavigate } from 'react-router-dom'
import { FormButton } from '../../app/components/formik/button'
import { FormInput } from '../../app/components/formik/input'
import { To } from '../../app/router/to'
import { BUTTON, RESTORE_PASSWORD_PAGE } from '../../app/utils/constants'
import { ErrorBox } from '../../app/components/error/ErrorBox'
import { errorMessage } from '../../app/utils/rest'
import { usePasswordRecoveryMutation } from '../auth/hooks/usePasswordRecoveryMutation'
import { validateEmail } from '../../app/utils/validate'
import { AuthError } from 'firebase/auth'

type FormValues = {
  email: string
}

const validate = (values: FormValues) => {
  const errors: FormikErrors<FormValues> = {}

  if (!values.email) {
    errors.email = 'Required'
  } else if (validateEmail(values.email)) {
    errors.email = 'Invalid email'
  }

  return errors
}

const initialValues: FormValues = { email: '' }

export const PasswordRecoveryPage: FC = () => {
  const classes = useStyles()
  const theme = useTheme()
  const navigate = useNavigate()

  const passwordRecoveryMutation = usePasswordRecoveryMutation()

  const [error, serError] = useState('')

  const handleSubmitError = (error: AuthError) => {
    serError(errorMessage(error))
  }

  const handleSubmitSuccess = (_: unknown, params: { email: string }) => {
    const search = new URLSearchParams(params).toString()
    navigate({ pathname: To.passwordRecoveryInfo, search })
  }

  const handleSubmit = async (values: FormValues) => {
    await passwordRecoveryMutation.mutateAsync(values, {
      onError: handleSubmitError,
      onSuccess: handleSubmitSuccess,
    })
  }

  return (
    <div className={classes.root}>
      <Typography className={classes.header} variant={'h1'}>
        {RESTORE_PASSWORD_PAGE.header}
      </Typography>
      <Formik initialValues={initialValues} validate={validate} onSubmit={handleSubmit}>
        <Form className={classes.form}>
          <ErrorBox className={classes.error} open={Boolean(error)} error={error} />
          <FormInput type={'text'} id={'email'} label={'Введите ваш e-mail'} placeholder={'Введите ваш эл.адрес'} />
          <FormButton
            title={BUTTON.next}
            type={'submit'}
            color={theme.palette.pink.main}
            textColor={theme.palette.pink.contrastText}
            withoutBorder
          />
        </Form>
      </Formik>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '390px',
    minHeight: '100vh',
    margin: '0 auto',
    padding: theme.spacing(5, 2, 2),
  },
  header: {
    marginBottom: 40,
  },
  form: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  error: {
    marginBottom: theme.spacing(2),
  },
}))
