import { makeStyles, Typography } from '@material-ui/core'
import React from 'react'

export const PublicOfferPage = () => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Typography className={classes.header} variant={'h1'}>
        Публичная оферта
      </Typography>
      <Typography variant={'body1'} align={'justify'}>
        Индивидуальный предприниматель, ИП Шахиджанова Анастасия Владиславовна, ОГРН 320508100368411, ИНН 213003811759,
        именуемая в дальнейшем Исполнитель, выражает намерение заключить настоящий Договор возмездного оказания услуг на
        условиях, содержащихся в настоящей публичной Оферте, с заинтересованным лицом, в дальнейшем именуемым
        Заказчиком. Настоящая публичная Оферта действует для всех услуг и продуктов, относящихся к предмету настоящей
        Оферты и предлагаемых на сайте с доменным именем http://zipperz.ru.
      </Typography>
      <ol>
        <Typography variant={'body1'} component={'li'}>
          ОСНОВНЫЕ ПОНЯТИЯ, ИСПОЛЬЗУЕМЫЕ В ПУБЛИЧНОЙ ОФЕРТЕ
          <ol>
            <Typography variant={'body1'} component={'li'} align={'justify'}>
              Публичная Оферта - данный документ, размещенный на сайте с доменным именем http://zipperz.ru далее по
              тексту – («Оферта»).
            </Typography>
            <Typography variant={'body1'} component={'li'} align={'justify'}>
              Акцепт Оферты — полное и безоговорочное принятие настоящей Оферты Заказчиком в порядке, предусмотренном
              п.3 настоящей Оферты. Акцептом (безусловным принятием) условий настоящей̆ Оферты (моментом заключения
              Договора), в соответствии с пунктами 1 и 3 статьи 438 Гражданского Кодекса Российской̆ Федерации, считается
              факт оплаты Заказчиком Услуг, выбранных Заказчиком в соответствии с п. 1.1 настоящего Договора и
              предоставляемых Исполнителем в соответствии с условиями настоящего Договора, при условии получения
              Исполнителем заказа на Услугу от Заказчика через сайт Исполнителя. Договор считается заключенным без
              подписания в каждом конкретном случае, т.к. акцепт оферты приравнивается к заключению Договора на
              указанных ниже условиях. Договор, заключенный посредством акцепта настоящей оферты, регламентируется
              нормами гражданского законодательства о договоре присоединения (статья 428 ГК РФ), поскольку его условия
              определены Исполнителем в настоящей оферте и могут быть приняты любым лицом не иначе как путем
              присоединения к предложенному Договору в целом. При этом в соответствии с пунктом 3 статьи 434 и пунктом 3
              статьи 438 Гражданского Кодекса Российской̆ Федерации настоящий Договор признается заключенным Сторонами в
              письменной форме. Заказчик обязан полностью ознакомиться с настоящим документом до момента внесения
              оплаты.
            </Typography>
            <Typography variant={'body1'} component={'li'} align={'justify'}>
              Исполнитель – ИП Шахиджанова Анастасия Владиславовна, ОГРН 320508100368411, ИНН 213003811759.
            </Typography>
            <Typography variant={'body1'} component={'li'} align={'justify'}>
              Сайт — сайт http://zipperz.ru.
            </Typography>
          </ol>
        </Typography>
        <Typography variant={'body1'} component={'li'}>
          ПРЕДМЕТ ОФЕРТЫ
          <ol>
            <Typography variant={'body1'} component={'li'} align={'justify'}>
              Заказчик поручает и обязуется оплатить, а Исполнитель принимает на себя обязательства оказать услуги по
              подключению платной подписки мобильного приложения «Молния Z!» в виде предоставления Заказчику технической
              возможности провести оплату подписки по тарифам указанным на Сайте Исполнителя и выбранным Заказчиком
              (далее – Услуги).
            </Typography>
            <Typography variant={'body1'} component={'li'} align={'justify'}>
              Услуги оказываются путем использования глобальной сети Интернет, эквайринга и иных средств электросвязи и
              телекоммуникаций, программного обеспечения и баз данных Исполнителя.
            </Typography>
            <Typography variant={'body1'} component={'li'} align={'justify'}>
              Услуги включают в себя: - предоставление доступа ко всем действующим функциям мобильного приложения
              «Молния Z!» на оплаченный период который выбирается Заказчиком в момент оплаты в соответствующем разделе
              на сайте http://zipperz.ru - предоставление ссылок на скачивание мобильного приложения «Молния Z!» в App
              store и Google Play.
            </Typography>
            <Typography variant={'body1'} component={'li'} align={'justify'}>
              Исполнитель ставит Заказчика в известность о том, что в течении срока подписки оплаченной Заказчиком
              мобильное приложение «Молния Z!» может выходить в новых версиях, с новыми функциями и дополнениями, либо с
              коррекцией существующих на момент оплаты функций, что не отменяет возможность для Заказчика пользоваться
              старой версией.
            </Typography>
            <Typography variant={'body1'} component={'li'} align={'justify'}>
              Услуга Заказчику оказывается сразу с момента поступления денег на расчетный счёт Исполнителя, о чём
              Исполнитель уведомляется на сайте http://zipperz.ru сообщением о том, что оплата прошла успешно.
            </Typography>
            <Typography variant={'body1'} component={'li'} align={'justify'}>
              В случае если оплата не прошла, Исполнитель так же уведомляется соответствующим сообщением на сайте
              http://zipperz.ru ; В случае неуспешной оплаты Услуга Заказчику не оказывается.
            </Typography>
          </ol>
        </Typography>
        <Typography variant={'body1'} component={'li'}>
          АКЦЕПТ ОФЕРТЫ
          <ol>
            <Typography variant={'body1'} component={'li'} align={'justify'}>
              Акцептом настоящей Оферты считается 100 (сто)% предоплата Заказчиком услуг Исполнителя.
            </Typography>
            <Typography variant={'body1'} component={'li'} align={'justify'}>
              Акцепт предполагает, что Заказчик ознакомлен с условиями настоящей Оферты, безоговорочно согласен со всеми
              из них. В случае несогласия с какими-либо условиями Заказчик отказывается от заключения договора.
            </Typography>
            <Typography variant={'body1'} component={'li'} align={'justify'}>
              Договор Оферты между Исполнителем и Заказчиком считается заключенным в момент Акцепта.
            </Typography>
          </ol>
        </Typography>
        <Typography variant={'body1'} component={'li'}>
          ПРАВА И ОБЯЗАННОСТИ СТОРОН
          <ol>
            <Typography variant={'body1'} component={'li'} align={'justify'}>
              Исполнитель обязан:
              <ul>
                <Typography variant={'body1'} component={'li'} align={'justify'}>
                  оказать услуги в соответствии с настоящей Офертой и имеющимся на сайте описанием Услуг сайте
                  http://zipperz.ru;
                </Typography>
                <Typography variant={'body1'} component={'li'} align={'justify'}>
                  предоставить Заказчику достоверную информацию о предоставляемых услугах;
                </Typography>
                <Typography variant={'body1'} component={'li'} align={'justify'}>
                  своевременно предоставить Заказчику информацию обо всех изменениях в условиях настоящей Оферты, а
                  также предоставить Заказчику информацию о новых Услугах;
                </Typography>
                <Typography variant={'body1'} component={'li'} align={'justify'}>
                  указывать на сайте http://zipperz.ru актуальную цену за предоставляемые.
                </Typography>
              </ul>
            </Typography>
            <Typography variant={'body1'} component={'li'} align={'justify'}>
              Исполнитель вправе:
              <ol>
                <Typography variant={'body1'} component={'li'} align={'justify'}>
                  включить адрес электронной почты Заказчика в список почтовой рассылки новостей проекта. А также
                  использовать рассылку на телефон, указанный Заказчиком, если таковой указан;
                </Typography>
                <Typography variant={'body1'} component={'li'} align={'justify'}>
                  оказывать Услуги лично либо с привлечением третьих лиц, отвечая за их действия перед Заказчиком;
                </Typography>
                <Typography variant={'body1'} component={'li'} align={'justify'}>
                  определять самостоятельно методику и формат оказания Услуг, порядок предоставления доступа к
                  материалам;
                </Typography>
              </ol>
            </Typography>
            <Typography variant={'body1'} component={'li'} align={'justify'}>
              Заказчик обязан:
              <ul>
                <Typography variant={'body1'} component={'li'} align={'justify'}>
                  принять безусловно и в полном объеме условия настоящей Оферты;
                </Typography>
                <Typography variant={'body1'} component={'li'} align={'justify'}>
                  внимательно изучить на сайте информацию об Услуге и ее стоимости;
                </Typography>
                <Typography variant={'body1'} component={'li'} align={'justify'}>
                  выбрать необходимую Услугу, а также способ ее оплаты;
                </Typography>
                <Typography variant={'body1'} component={'li'} align={'justify'}>
                  ввести в форме заказа услуги достоверные данные;
                </Typography>
                <Typography variant={'body1'} component={'li'} align={'justify'}>
                  оплатить услугу на условиях 100% предоплаты.
                </Typography>
              </ul>
            </Typography>
            <Typography variant={'body1'} component={'li'} align={'justify'}>
              Материалы, полученные Заказчиком от Исполнителя посредством мобильного приложения «Молния Z!» , могут
              использоваться Заказчиком только в личных целях.
            </Typography>
            <Typography variant={'body1'} component={'li'} align={'justify'}>
              Заказчику запрещается воспроизводить, повторять и копировать, продавать и перепродавать, а также
              использовать в каких-либо коммерческих целях материалы, предоставленные Исполнителем.
            </Typography>
            <Typography variant={'body1'} component={'li'} align={'justify'}>
              Заказчик может направить Исполнителю все претензии относительно качества оказываемых услуг, в срок, не
              превышающий пяти рабочих дней с момента начала оказания услуги. В случае, если такая претензия не получена
              Исполнителем, считается, что Заказчик не имеет претензий к качеству услуг, а услуги считаются оказанными в
              полном объеме и надлежащим образом.
            </Typography>
            <Typography variant={'body1'} component={'li'} align={'justify'}>
              Заказчик может потребовать возврата оплаты за услуги в порядке, предусмотренном настоящей Офертой в
              соответствии с законодательством Российской Федерации, а так же в случае если качество Услуги не
              соответсвует её описанию на сайте http://zipperz.ru , а так же описанию внутри мобильного приложения
              «Молния Z!». Денежные средства в этом случае возвращаются на расчётный счёт или привязанную к нему карту с
              которой была оплачена Услуга.
            </Typography>
          </ol>
        </Typography>
        <Typography variant={'body1'} component={'li'}>
          ОКАЗАНИЕ УСЛУГ
          <ol>
            <Typography variant={'body1'} component={'li'} align={'justify'}>
              Стоимость Услуг по Договору определяется по тарифу Исполнителя, размещенному на Сайте Исполнителя
              http://zipperz.ru Тарифный план является официальным документом Исполнителя и публикуются на Сайте
              Исполнителя http://zipperz.ru
            </Typography>
            <Typography variant={'body1'} component={'li'} align={'justify'}>
              Исполнитель имеет право по своему усмотрению вносить изменения в действующий Тариф на сайте
              http://zipperz.ru, но на уже оплаченную Услугу Заказчиком это не влияет.
            </Typography>
            <Typography variant={'body1'} component={'li'} align={'justify'}>
              В случае, если Заказчик предоставил не правильный электронный почтовый адрес и/или телефон (если есть поле
              «телефон» и оно обязательно к заполнению), Услуга считается оказанной в любом случае при направлении
              письма или сообщения по тому адресу, даже если ошибочному, который Заказчик указал. В этом случае
              Исполнитель предоставляет возможность Заказчику на сайте http://zipperz.ru восстановить пароль или
              связаться с техподдержкой
            </Typography>
          </ol>
        </Typography>
        <Typography variant={'body1'} component={'li'}>
          ОПЛАТА УСЛУГ
          <ol>
            <Typography variant={'body1'} component={'li'} align={'justify'}>
              Стороны пришли к соглашению, что Услуги оплачиваются на условиях предварительной оплаты. Порядок внесения
              платежа по действующему тарифу, сроки оплаты и условия рассрочки размещаются Исполнителем на сайте
              Исполнителя http://zipperz.ru.
            </Typography>
            <Typography variant={'body1'} component={'li'} align={'justify'}>
              Услуга предоставляется Исполнителем после полной, 100 (сто) % ее оплаты Заказчиком. Такая оплата означает
              ознакомление и полное согласие Заказчика со всеми условиями Оферты. Оплата Заказчиком услуги считается
              акцептом оферты.
            </Typography>
            <Typography variant={'body1'} component={'li'} align={'justify'}>
              Моментом оплаты считается зачисление денежных средств на текущий счет Исполнителя.
            </Typography>
            <Typography variant={'body1'} component={'li'} align={'justify'}>
              Возврат Исполнителем денежных средств, оплаченных Заказчиком за качественно оказанную услугу, не
              предусмотрен. Качественно оказанной Услугой является стабильная работа мобильного приложения «Молния Z!» в
              течении всего срока подписки оплаченного Заказчиком.
            </Typography>
            <Typography variant={'body1'} component={'li'} align={'justify'}>
              Заказчик самостоятельно несет ответственность за ошибки, которые допущены им при оплате услуг Исполнителя.
              В случае неправильно указанных данных, почты, ФИО, телефона, Исполнитель имеет право отказаться от
              выполнения Услуги, если связаться с получателем Услуги будет не возможно.
            </Typography>
            <Typography variant={'body1'} component={'li'} align={'justify'}>
              Заказчик самостоятельно несет ответственность за правильность производимых им платежей.
            </Typography>
            <Typography variant={'body1'} component={'li'} align={'justify'}>
              В случае осуществления возврата оплаченных денежных средств, по основаниям, предусмотренным настоящим
              Договором, возврат производится по тем же реквизитам, по которым был получен платеж, если в требовании
              Заказчиком не указано иное.
            </Typography>
          </ol>
        </Typography>
        <Typography variant={'body1'} component={'li'}>
          ПЕРСОНАЛЬНЫЕ ДАННЫЕ О ЗАКАЗЧИКЕ И ИХ КОНФИДЕНЦИАЛЬНОСТЬ
          <ol>
            <Typography variant={'body1'} component={'li'} align={'justify'}>
              Заказчик дает разрешение Исполнителю на сбор, обработку и хранение персональных данных, которые
              предоставлены Заказчиком при заказе услуг (фамилия, имя, отчество, адрес электронной почты и телефон).
            </Typography>
            <Typography variant={'body1'} component={'li'} align={'justify'}>
              Исполнитель использует вышеуказанные персональные данные о Заказчике для:
              <ul>
                <Typography variant={'body1'} component={'li'} align={'justify'}>
                  обратной связи с Заказчиком;
                </Typography>
                <Typography variant={'body1'} component={'li'} align={'justify'}>
                  исполнения своих обязательств по оказанию услуг в рамках договора оферты.
                </Typography>
              </ul>
            </Typography>
            <Typography variant={'body1'} component={'li'} align={'justify'}>
              Получение писем рассылки новостей является добровольным. Заказчик может в любое время отказаться от
              получения писем, кликнув на ссылку для отписки, которая присутствует в каждом получаемом письме. В этом
              случае адрес электронной почты Заказчика будет автоматически удален из базы рассылки.
            </Typography>
            <Typography variant={'body1'} component={'li'} align={'justify'}>
              Персональные данные Заказчика могут быть раскрыты третьим лицам только с его разрешения или по требованию
              государственных органов в порядке, предусмотренном Федеральным законом «О персональных данных».
            </Typography>
            <Typography variant={'body1'} component={'li'} align={'justify'}>
              Заказчик вправе требовать уничтожения/обезличивания его персональных данных, которые хранятся у
              Исполнителя, путем отправления письма на электронную почту техподдержки Исполнителя указанную на сайте
              http://zipperz.ru.
            </Typography>
          </ol>
        </Typography>
        <Typography variant={'body1'} component={'li'}>
          ОТВЕТСТВЕННОСТЬ СТОРОН
          <ol>
            <Typography variant={'body1'} component={'li'} align={'justify'}>
              Исполнитель не дает Заказчику никаких гарантий относительно предоставляемых Услуг в случае санкционной
              политики со стороны компаний Apple и Google в отношении Российской Федерации, её граждан и юридических
              лиц, которая может затронуть стабильную работу мобильного приложения «Молния Z!» размещенного для
              скачивания в магазинах данных компаний.
            </Typography>
            <Typography variant={'body1'} component={'li'} align={'justify'}>
              Заказчик самостоятельно несет полную ответственность за последствия любого рода, в том числе, но не
              исключительно, за ожидаемые результаты от полученной Услуги, которые могут наступить в результате
              предоставления Услуг Исполнителем.
            </Typography>
            <Typography variant={'body1'} component={'li'} align={'justify'}>
              В случае предоставления Заказчиком заведомо ложных данных Исполнитель отказывается от любой
              ответственности, претензии таких Заказчиков не рассматриваются.
            </Typography>
            <Typography variant={'body1'} component={'li'} align={'justify'}>
              В случае нарушений в работе сети Интернет, оборудования, программного обеспечения Заказчика, Исполнитель
              не несет ответственности за невозможность предоставления Услуги.
            </Typography>
            <Typography variant={'body1'} component={'li'} align={'justify'}>
              Исполнитель не несет ответственности за любые убытки, причиненные Заказчику, в том числе, но не
              исключительно, в результате действий или бездействия, предоставление недостоверных сведений (информации)
              другими Заказчиками.
            </Typography>
            <Typography variant={'body1'} component={'li'} align={'justify'}>
              Суммарная ответственность Исполнителя по любым искам и / или претензиями (в том числе, но не
              исключительно, за невыполнение условий Оферты) не может превышать сумму платежа, уплаченного Заказчиком за
              услугу Исполнителя.
            </Typography>
            <Typography variant={'body1'} component={'li'} align={'justify'}>
              Заказчик, акцептируя данную Оферту, принимает на себя также риски неполучения прибыли и риски возможных
              убытков, связанных с использованием информации, полученной Заказчиком при предоставлении услуг
              Исполнителем.
            </Typography>
            <Typography variant={'body1'} component={'li'} align={'justify'}>
              Заказчик, акцептируя данную Оферту, подтверждает свою осведомленность о статусе образования Исполнителя,
              его компетенции и не имеет к ним претензий.
            </Typography>
            <Typography variant={'body1'} component={'li'} align={'justify'}>
              Исполнитель не несет ответственности за получение результата, не соответствующего ожиданиям Заказчика,
              поскольку успешность использования Заказчиком полученной информации зависит от многих неизвестных
              Исполнителю факторов.
            </Typography>
            <Typography variant={'body1'} component={'li'} align={'justify'}>
              Исполнитель не несет ответственности за несоответствие содержания Услуги ожиданиям Заказчика, если Услуга
              совпадает с её описанием на сайте Исполнителя.
            </Typography>
            <Typography variant={'body1'} component={'li'} align={'justify'}>
              Ни при каких условиях и обстоятельствах Исполнитель не несет ответственности перед третьими лицами за
              использование ссылок и информации, полученной Заказчиком во время услуги и переданной им, а также за
              принятые решения и/или Заказчиком и / или любыми третьими лицами на основании информации, полученной
              Заказчиками при предоставлении услуги Исполнителем.
            </Typography>
            <Typography variant={'body1'} component={'li'} align={'justify'}>
              Заказчик, подтверждает, что является дееспособным гражданином и обладает правами на принятие условий
              настоящей Оферты. Исполнитель освобождается от любой ответственности, в случае, если Заказчик нарушил
              данный пункт Оферты, а также договора в данном случае будет считаться не заключенным.
            </Typography>
            <Typography variant={'body1'} component={'li'} align={'justify'}>
              Заказчик не несет ответственности за регистрационные данные, которые были указаны Заказчиком при
              взаимодействии с информационным полем Сайта http://zipperz.ru в процессе регистрации заказа.
            </Typography>
            <Typography variant={'body1'} component={'li'} align={'justify'}>
              В случае, если Заказчик присваивает изображение, презентацию, картинки, текст, видео и т.п., Исполнитель
              имеет право в судебном порядке потребовать от Заказчика возмещение ущерба в размере 50 000 (Пятьдесят
              тысяч) рублей̆ и запрещения продолжения плагиата Заказчиком.
            </Typography>
            <Typography variant={'body1'} component={'li'} align={'justify'}>
              В случае, если Заказчик имея ввиду коммерческую выгоду умышленно распространяет видеозаписи, документы и
              иной контент, предоставленный̆ Исполнителем в рамках исполнения настоящего Договора, Исполнитель вправе
              взыскать с Заказчика штраф в размере 100 000 (Сто тысяч) рублей̆.
            </Typography>
          </ol>
        </Typography>
        <Typography variant={'body1'} component={'li'}>
          УРЕГУЛИРОВАНИЕ СПОРОВ, ПОРЯДОК РАССМОТРЕНИЯ ТРЕБОВАНИЙ
          <ol>
            <Typography variant={'body1'} component={'li'} align={'justify'}>
              Исполнитель прикладывает все усилия, чтобы не допустить нарушения чьих-либо прав при оказании услуг.
            </Typography>
            <Typography variant={'body1'} component={'li'} align={'justify'}>
              В требовании о возврате оплаченных за услуги денежных средств должно быть указано:
              <ul>
                <Typography variant={'body1'} component={'li'} align={'justify'}>
                  фамилия, имя, отчество лица, которое требует возврата;
                </Typography>
                <Typography variant={'body1'} component={'li'} align={'justify'}>
                  номер заказа, дата, сумма платежа;
                </Typography>
                <Typography variant={'body1'} component={'li'} align={'justify'}>
                  причины возврата;
                </Typography>
                <Typography variant={'body1'} component={'li'} align={'justify'}>
                  паспортные данные;
                </Typography>
                <Typography variant={'body1'} component={'li'} align={'justify'}>
                  реквизиты банковского счета;
                </Typography>
                <Typography variant={'body1'} component={'li'} align={'justify'}>
                  телефон, адрес электронной почты, почтовый адрес для связи.
                </Typography>
              </ul>
            </Typography>
            <Typography variant={'body1'} component={'li'} align={'justify'}>
              Требование необходимо прислать в отсканированном виде с подписью лица, которое просит осуществить возврат
              денежных средств, на электронную почту в техподдержку указанную на сайте Исполнителя http://zipperz.ru.
            </Typography>
            <Typography variant={'body1'} component={'li'} align={'justify'}>
              Заказчик понимает, что Исполнитель не несет никакой ответственности относительно понимания и применения
              Заказчиком рекомендаций и советов, предложенных на онлайн тренингах, вебинарах, коуч-сессиях и иных
              мероприятиях и Услугах Исполнителя. Все риски и ответственность за последствия применения полученной
              информации в результате прохождения онлайн тренингов, вебинаров, коуч-сессий и иных мероприятий и Услугах
              несет Заказчик. Заказчик ознакомлен с тем, что в случае имеющихся у него заболеваний, прежде чем начинать
              какую-либо диету, спортивные нагрузки и тренировочный процесс, он должен проконсультироваться с врачом,
              который должен выдать ему разрешение и рекомендации. В случае если Заказчик решается купить и следовать
              рекомендациям мобильного приложения «Молния Z!», ответственность за возможные негативные последствия
              ложится на Заказчика. В случае проявления болезненных симптомов, следует незамедлительно обратиться к
              врачу и прекратить следование рекомендациям мобильного приложения «Молния Z!».
            </Typography>
            <Typography variant={'body1'} component={'li'} align={'justify'}>
              Все споры между Заказчиком и Исполнителем, если стороны не урегулировали их в досудебном порядке, решаются
              в судах в соответствии с российским законодательством.
            </Typography>
          </ol>
        </Typography>
        <Typography variant={'body1'} component={'li'}>
          СРОК ДЕЙСТВИЯ И ИЗМЕНЕНИЕ ДОГОВОРА
          <ol>
            <Typography variant={'body1'} component={'li'} align={'justify'}>
              Настоящая Оферта и ее Акцепт образуют в совокупности договор возмездного оказания услуг, заключенный на
              условиях настоящей Оферты.
            </Typography>
            <Typography variant={'body1'} component={'li'} align={'justify'}>
              Договор возмездного оказания услуг вступает в силу с момента Акцепта настоящей Оферты Заказчиком и
              действует до исполнения обязательств Исполнителя по оказанию услуг.
            </Typography>
            <Typography variant={'body1'} component={'li'} align={'justify'}>
              Заказчик соглашается, что внесение изменений и дополнений в настоящую Оферту влечет за собой внесение
              изменений и дополнений в уже заключенный и действующий между Исполнителем и Заказчиком Договор, и они
              вступают в силу одновременно с такими изменениями в настоящей Оферте.
            </Typography>
            <Typography variant={'body1'} component={'li'} align={'justify'}>
              Если настоящая Оферта отзывается в течение срока действия Договора, он считается расторгнутым с момента
              отзыва Оферты. При этом Исполнитель обязан возвратить денежные средства, оплаченные Заказчиком, за вычетом
              затрат, понесенных Исполнителем на оказанные услуги.
            </Typography>
            <Typography variant={'body1'} component={'li'} align={'justify'}>
              Исполнитель имеет право внести изменения в условия Оферты или отозвать Оферту в любой момент по своему
              усмотрению.
            </Typography>
          </ol>
        </Typography>
      </ol>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    maxWidth: 390,
    margin: '0 auto',
    padding: theme.spacing(5, 2),

    '& ol': {
      paddingInlineStart: theme.spacing(2),
      listStyle: 'none',
      counterReset: 'list-item-number',
    },
    '& ul': {
      paddingInlineStart: theme.spacing(2),
    },
    '& ol > li': {
      counterIncrement: 'list-item-number',
    },
    '& ol > li:before': {
      content: 'counters(list-item-number, ".") ". "',
    },
  },
  header: {
    marginBottom: theme.spacing(2),
  },
}))
