import { useMutation } from '@tanstack/react-query'
import { AuthError } from 'firebase/auth'
import { FirstParameter, Unwrap } from '../../../app/types/common'
import { useAuth } from '../AuthProvider'

type Auth = ReturnType<typeof useAuth>
type Response = Unwrap<ReturnType<Auth['signIn']>>
type Error = AuthError
type Variables = FirstParameter<Auth['signIn']>

export const useSignInMutation = () => {
  const { signIn } = useAuth()
  return useMutation<Response, Error, Variables>(signIn)
}
