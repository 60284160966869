import { ICarouselItem } from '../../features/mainPage/components/carouselPage/carouselItem'
import firstImage from '../assets/icons/screenshots/1page.jpg'
import secondImage from '../assets/icons/screenshots/2page.jpg'
import threeImage from '../assets/icons/screenshots/3page.jpg'
import fourImage from '../assets/icons/screenshots/4page.jpg'
import fiveImage from '../assets/icons/screenshots/5page.jpg'

export const INFO: ICarouselItem[] = [
  {
    id: 11111,
    src: firstImage,
    title:
      '«Молния Z!» до грамма рассчитает сколько именно и каких продуктов тебе надо съесть! А рецепт как именно надо готовить блюдо, всегда под рукой',
  },
  {
    id: 22222,
    src: secondImage,
    title: 'Создаст твой личный марафон стройного тела в котором ты сможешь ежедневно видеть свои результаты.',
  },
  {
    id: 33333,
    src: threeImage,
    title:
      '«Молния Z!» - это твой персональный диетолог, который рассчитает твою дневную норму калорий, белков, жиров и углеводов, а также норму потребления воды',
  },
  {
    id: 44444,
    src: fourImage,
    title:
      'Предложит пройти тест и исходя из твоих ответов подобрать комфортную схему питания и меню с вкусными и быстрыми рецептами.',
  },
  {
    id: 55555,
    src: fiveImage,
    title:
      'Твой персональный диетолог будет хранить всю историю твоего питания и потребления воды, которую ты всегда можешь посмотреть и вернуться в нужный день',
  },
]

export const MOCK_STATISTIC = [
  {
    title: 'Более 7 000 продуктов',
    description: 'в базе приложения',
  },
  {
    title: 'более 100 вкуснейших рецепта',
    description: 'для Баланса и Кето',
  },
]

export const STEPS = [
  {
    label: '01',
    description: `Нажмите “Восстановить пароль”`,
  },
  {
    label: '02',
    description: 'В появившемся окне укажите почту, на которую регистрировали аккаунт',
  },
  {
    label: '03',
    description: `На почту придет ссылка для входа (проверьте папку “Спам”, “Промоакции”, “Рассылки”)`,
  },
  {
    label: '04',
    description: `Установите новый пароль`,
  },
  {
    label: '05',
    description: `По любым вопросам пишите в поддержку`,
  },
]

export const RATE_PAGE = {
  activeUser: {
    header: 'Ваша подписка истекла',
    helperText: 'Выберите удобный тариф',
  },
  newUser: {
    header: 'Выберите удобный тариф',
  },
}

export const LOGIN_PAGE = {
  header: 'Авторизация',
  helperText: '(Для зарегистрированных пользователей)',
}

export const BUTTON = {
  signIn: 'Войти',
  signOut: 'Выйти',
  signUp: 'Зарегистрироваться',
  pay: 'Оплатить',
  subscribe: 'Оформить подписку',
  present: 'Подарить',
  help: 'Написать в тех.поддержку',
  next: 'Далее',
}

export const ABOUT_PAGE = {
  header: '“Молния Z!” - это:',
  description: 'Меню периодически обновляется, все блюда подбираются по принципу- Быстро, Вкусно, Доступно',
  downloadApp: 'Скачать приложение',
  footer: 'Молния Z! © 2021 Mikhail Shakhidzhanov',
}

export const SUBSCRIBE = {
  oneMonth: {
    title: '1 месяц',
    price: '229 ₽',
  },
  sixMonth: {
    title: '6 месяцев',
    price: '999 ₽',
  },
  oneYear: {
    title: '1 год',
    price: '1799 ₽',
  },
}

export const REGISTRATION_PAGE = {
  header: 'Зарегистрироваться',
  checkboxText: 'Убедитесь, что указанный адрес указан верно',
}

export const RESTORE_PASSWORD_PAGE = {
  header: 'Восстановление пароля',
}

export const CAROUSEL_PAGE = {
  header: 'Что умеет “Молния Z!”?',
}

export const ERRORS: Record<number | string, string> = {
  // Use backend error codes
  // or error codes from https://firebase.google.com/docs/auth/admin/errors
  'auth/user-not-found': 'Неверный email или пароль',
  'auth/wrong-password': 'Неверный email или пароль',
  'auth/email-already-in-use': 'Пользователь с таким email уже существует',
}
