import React, { FC } from 'react'
import { alpha, makeStyles, Typography } from '@material-ui/core'
import { Link } from 'react-router-dom'
import { To } from 'app/router/to'

export const OfferAgreement: FC = () => {
  const classes = useStyles()

  return (
    <div className={classes.offer}>
      <Typography className={classes.text} variant={'body1'}>
        Нажимая оплатить, вы соглашаетесь с{' '}
        <Typography variant={'caption'} component={Link} to={To.publicOffer} target={'_blank'}>
          договором оферты
        </Typography>
        {', '}
        <Typography variant={'caption'} component={Link} to={To.paymentAgreement} target={'_blank'}>
          соглашением о рекуррентных платежах
        </Typography>
        {', '}и политикой конфиденциальности.
      </Typography>
    </div>
  )
}

const useStyles = makeStyles(() => ({
  offer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'row',
  },
  text: {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '11px',
    lineHeight: '14px',
    color: alpha('#818C99', 0.6),
    letterSpacing: '0.066px',
  },
}))
