import React, { useRef } from 'react'
import cn from 'classnames'
import { alpha, makeStyles, Typography, useTheme } from '@material-ui/core'
import { Carousel } from 'react-responsive-carousel'
import { CSSProperties } from '@material-ui/core/styles/withStyles'
import { CarouselItem, ICarouselItem } from './carouselItem'
import { CAROUSEL_PAGE, INFO } from '../../../../app/utils/constants'

import 'react-responsive-carousel/lib/styles/carousel.min.css'

export type Props = {
  className?: string
}

export const CarouselPage = React.forwardRef<HTMLDivElement, Props>(({ className }, ref) => {
  const classes = useStyles()
  const theme = useTheme()

  const carouselRef = useRef<Carousel | null>(null)

  const handleTouchStart = () => {
    carouselRef.current?.stopOnHover()
  }

  const handleTouchEnd = () => {
    carouselRef.current?.startOnLeave()
  }

  const indicatorStyles: CSSProperties = {
    display: 'inline-block',
    margin: theme.spacing(0, 1.5),
    borderRadius: '50%',
    height: 11,
    width: 11,
  }

  return (
    <div ref={ref} className={cn(classes.carousel, className)}>
      <Typography variant={'h1'} className={classes.title}>
        {CAROUSEL_PAGE.header}
      </Typography>
      <div onTouchStart={handleTouchStart} onTouchEnd={handleTouchEnd}>
        <Carousel
          ref={carouselRef}
          width={390}
          interval={5000}
          swipeScrollTolerance={30}
          showArrows={false}
          showStatus={false}
          showThumbs={false}
          stopOnHover={true}
          emulateTouch
          swipeable
          autoPlay
          infiniteLoop
          preventMovementUntilSwipeScrollTolerance
          renderIndicator={(onClickHandler, isSelected, index) => {
            return (
              <li
                style={{
                  ...indicatorStyles,
                  backgroundColor: isSelected ? theme.palette.pink.main : alpha('#818c99', 0.2),
                  cursor: 'pointer',
                }}
                value={index}
                key={index}
                role={'button'}
                tabIndex={0}
                onClick={onClickHandler}
                onKeyDown={onClickHandler}
              />
            )
          }}
        >
          {INFO.map((item: ICarouselItem) => (
            <CarouselItem item={item} key={item.id} />
          ))}
        </Carousel>
      </div>
    </div>
  )
})

const useStyles = makeStyles(() => ({
  carousel: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
  },
  title: {
    marginBottom: 40,
    width: '228px',
    textAlign: 'center',
  },
}))
