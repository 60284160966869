const apiUrl: string = process.env['REACT_APP_REST_API'] || ''
const googleAppUrl: string = process.env['REACT_APP_GOOGLE_APP_URL'] || ''
const appleAppUrl: string = process.env['REACT_APP_APPLE_APP_URL'] || ''
const supportUri: string = process.env['REACT_APP_SUPPORT_URI'] || ''

const isDev = process.env.NODE_ENV === 'development'
const isProd = process.env.NODE_ENV === 'production'

const requiredFields = { apiUrl, googleAppUrl, appleAppUrl, supportUri }

const validateRequiredFields = (requiredFields: Record<string, unknown>) => {
  const required = Object.entries(requiredFields)
    .filter(([, value]) => !value)
    .map(([key]) => key)

  if (required.length) {
    throw new Error(`Required configuration fields: ${required.join(', ')}`)
  }
}

validateRequiredFields(requiredFields)

export default {
  apiUrl,
  isDev,
  isProd,
  googleAppUrl,
  appleAppUrl,
  supportUri,
}
