import React, { FC } from 'react'
import { Checkbox } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

interface IBasicCheckbox {
  checked: boolean
  onChange: () => void
}

export const BasicCheckbox: FC<IBasicCheckbox> = ({ checked, onChange }) => {
  const checkBoxStyles = () => ({
    root: {
      padding: 0,
      marginRight: 16,
      '& .MuiSvgIcon-root': {
        fill: 'white',
        '&:hover': {
          backgroundColor: 'white',
        },
      },
      '&$checked': {
        '& .MuiIconButton-label': {
          position: 'relative',
          zIndex: 0,
          border: '1px solid #EFCAFF',
          borderRadius: 2,
        },
        '& .MuiIconButton-label:after': {
          content: '""',
          // left: 4,
          // top: 4,
          height: 7.5,
          width: 10,
          position: 'absolute',
          backgroundColor: '#EFCAFF',
          zIndex: -1,
          borderColor: 'transparent',
        },
      },
      '&:not($checked) .MuiIconButton-label': {
        position: 'relative',
        zIndex: 0,
        border: '1px solid #EFCAFF',
        borderRadius: 2,
      },
      '&:not($checked) .MuiIconButton-label:after': {
        content: '""',
        // left: 4,
        // top: 4,
        height: 21,
        width: 22,
        position: 'absolute',
        backgroundColor: 'white',
        zIndex: -1,
        borderColor: 'transparent',
      },
    },
    checked: {},
  })

  const CustomCheckbox = withStyles(checkBoxStyles)(Checkbox)

  return <CustomCheckbox checked={checked} onChange={onChange} disableRipple />
}
