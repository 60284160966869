import cn from 'classnames'
import { MouseEvent } from 'react'
import { alpha, makeStyles, useTheme } from '@material-ui/core/styles'
import { useNavigate } from 'react-router-dom'
import { BasicButton } from '../../../../app/components/button/button'
import { ArrowDownSvg, LogoIcon } from '../../../../app/assets/icons'
import { To } from '../../../../app/router/to'
import { BUTTON } from '../../../../app/utils/constants'
import { useAuth } from '../../../auth/AuthProvider'
import { useSignOutMutation } from '../../../auth/hooks/useSignOutMutation'
import { Typography } from '@material-ui/core'

import Background from '../../../../app/assets/images/background.jpg'

type Props = {
  className?: string
  elementRef: any
}

export const FirstPage = ({ className, elementRef }: Props) => {
  const classes = useStyles()
  const theme = useTheme()
  const navigate = useNavigate()
  const { user } = useAuth()

  const { isSignedIn, isLoading } = useAuth()

  const signOutMutation = useSignOutMutation()

  const handleSignOut = () => {
    signOutMutation.mutateAsync()
  }

  const handleSignIn = () => {
    navigate(To.signin)
  }

  const handleProcessPurchase = () => {
    navigate(To.rate)
  }

  const executeScroll = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    elementRef && elementRef.current?.scrollIntoView()
  }

  return (
    <div className={cn(classes.root, className)}>
      <div className={classes.container}>
        <div className={classes.backdrop} />
        <LogoIcon className={classes.logo} />
        {user?.email && <Typography className={classes.email}>{`Email: ${user.email}`}</Typography>}
        <div className={classes.buttons}>
          <BasicButton
            className={classes.button}
            color={theme.palette.blue.main}
            textColor={theme.palette.blue.contrastText}
            title={BUTTON.present}
            fullWidth
            uppercase
            disabled
          />
          {isSignedIn ? (
            <BasicButton
              className={classes.button}
              color={theme.palette.yellow.main}
              textColor={theme.palette.yellow.contrastText}
              title={BUTTON.signOut}
              fullWidth
              uppercase
              disabled={isLoading}
              onClick={handleSignOut}
            />
          ) : (
            <BasicButton
              className={classes.button}
              color={theme.palette.yellow.main}
              textColor={theme.palette.yellow.contrastText}
              title={BUTTON.signIn}
              fullWidth
              uppercase
              disabled={isLoading}
              onClick={handleSignIn}
            />
          )}
          <BasicButton
            className={classes.button}
            color={theme.palette.pink.main}
            textColor={theme.palette.pink.contrastText}
            title={BUTTON.subscribe}
            fullWidth
            uppercase
            onClick={handleProcessPurchase}
          />
        </div>

        <button className={classes.scrollButton} type={'button'} onClick={executeScroll}>
          <ArrowDownSvg />
        </button>
      </div>
    </div>
  )
}

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '700px',
    backgroundImage: `url(${Background})`,
    backgroundSize: 'auto 100%',
    backgroundPosition: 'center',
  },
  container: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-around',
    width: '358px',
    height: '620px',
    zIndex: 0,
  },
  backdrop: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    borderRadius: 15,
    backgroundImage: 'linear-gradient(to bottom, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.6))',
    backdropFilter: 'blur(5px)',
    maskImage: 'radial-gradient(circle at center bottom, transparent calc(73px / 2), #fff 0)',
    zIndex: -1,
  },
  scrollButton: {
    boxSizing: 'border-box',
    position: 'absolute',
    bottom: 0,
    left: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    borderWidth: 0,
    padding: 0,
    width: 53,
    height: 53,
    transform: 'translate(-50%, 50%)',
    backgroundColor: 'rgba(255, 255, 255, 0.4)',
    backdropFilter: 'blur(25px)',
    cursor: 'pointer',
  },
  logo: {
    borderRadius: '15px',
    background: 'rgba(0, 0, 0, 0.2)',
    backdropFilter: 'blur(30px)',
  },
  email: {
    textAlign: 'center',
    fontWeight: 400,
    fontSize: 14,
    color: alpha('#111', 0.8),
  },
  buttons: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'column',
  },
  button: {
    marginBottom: 40,
  },
}))
