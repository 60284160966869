import React from 'react'
import cn from 'classnames'
import { Box, makeStyles } from '@material-ui/core'
import Skeleton from '@material-ui/lab/Skeleton'

type Props = {
  className?: string
  count: number
}

export const RateSkeleton = ({ className, count }: Props) => {
  const classes = useStyles()

  return (
    <Box className={cn(classes.root, className)}>
      {Array(count)
        .fill({})
        .map((_, index) => (
          <Skeleton key={index} className={classes.item} variant={'rect'} />
        ))}
    </Box>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    '&:empty': {
      display: 'none',
    },
  },
  item: {
    borderRadius: theme.spacing(1),
    height: 88,
    marginBottom: theme.spacing(2),

    '&:last-of-type': {
      marginBottom: 0,
    },
  },
}))
