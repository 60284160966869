import React from 'react'
import cn from 'classnames'
import { createStyles, makeStyles, Typography } from '@material-ui/core'

type Props = {
  className?: string
  image: string
  text: string
  alt?: string
}

export const ReviewsItem = ({ className, image, text, alt }: Props) => {
  const classes = useStyles()

  return (
    <div className={cn(classes.root, className)}>
      <img className={classes.image} src={image} alt={alt} />
      <Typography className={classes.text} align={'center'}>
        {text}
      </Typography>
    </div>
  )
}

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: 5,
      padding: theme.spacing(4, 4.5),
      backgroundColor: '#72d7fa',
    },
    image: {
      filter: 'drop-shadow(0px 12px 20px rgba(61, 75, 78, 0.45))',
    },
    text: {
      marginTop: theme.spacing(3),
      fontWeight: 800,
      fontSize: 15,
      lineHeight: '20px',
      color: theme.palette.common.black,
    },
  }),
)
