import { useMutation } from '@tanstack/react-query'
import { AuthError } from 'firebase/auth'
import { FirstParameter, Unwrap } from '../../../app/types/common'
import { useAuth } from '../AuthProvider'

type Auth = ReturnType<typeof useAuth>
type Response = Unwrap<ReturnType<Auth['signUp']>>
type Error = AuthError
type Variables = FirstParameter<Auth['signUp']>

export const useSignUpMutation = () => {
  const { signUp } = useAuth()
  return useMutation<Response, Error, Variables>(signUp)
}
