import React, { FC, useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core'
import Loader from '../loader/loader'
import { ICarouselItem } from '../../../features/mainPage/components/carouselPage/carouselItem'

interface IImageWithLoader {
  item: ICarouselItem
}

export const ImageWithLoader: FC<IImageWithLoader> = ({ item }) => {
  const classes = useStyles()
  const { src, title } = item

  const imgEl = React.useRef<HTMLImageElement>(null)

  const [isLoading, setLoading] = useState<boolean>(false)

  const onImageLoaded = () => setLoading(true)

  useEffect(() => {
    const imgElCurrent = imgEl.current

    if (imgElCurrent) {
      imgElCurrent.addEventListener('load', onImageLoaded)
      return () => imgElCurrent.removeEventListener('load', onImageLoaded)
    }
  }, [imgEl])

  return (
    <>
      <Loader position={!isLoading ? classes.loaderShow : classes.loaderHide} />
      <img ref={imgEl} src={src} alt={title} style={isLoading ? { display: 'inline-block' } : { display: 'none' }} />
    </>
  )
}

const useStyles = makeStyles(() => ({
  loaderShow: {
    display: 'block',
  },
  loaderHide: {
    display: 'none',
  },
}))
