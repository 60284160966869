import { PageLoader } from 'app/components/PageLoader'
import { ReactElement } from 'react'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import { To } from '../../app/router/to'
import { useAuth } from './AuthProvider'

type Props = {
  redirectTo?: string
  children?: ReactElement
}

export const AuthGuard = ({ redirectTo = To.signin, children }: Props) => {
  const location = useLocation()

  const { isSignedIn, isLoading } = useAuth()

  if (isLoading) {
    return <PageLoader />
  }

  if (!isSignedIn) {
    return <Navigate to={redirectTo} state={{ from: location }} replace />
  }

  return children ? children : <Outlet />
}
