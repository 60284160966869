import { initializeApp } from 'firebase/app'
import { getAuth, GoogleAuthProvider, OAuthProvider } from 'firebase/auth'

const firebaseConfig = {
  apiKey: 'AIzaSyAT8bLzXmpURs_3HuIGVN7jo-pMQDNElVo',
  authDomain: 'zipperz--a.firebaseapp.com',
  projectId: 'zipperz--a',
  storageBucket: 'zipperz--a.appspot.com',
  messagingSenderId: '751764946876',
  appId: '1:751764946876:web:3a3dc9e1f831f7dc3535b9',
  measurementId: 'G-310SRV0F2G',
}

const app = initializeApp(firebaseConfig)
const auth = getAuth(app)

auth.languageCode = 'ru'

const authProviders = {
  google: new GoogleAuthProvider(),
  apple: new OAuthProvider('apple.com'),
}

export const firebase = { app, auth, authProviders }
