import memoizee from 'memoizee'
import { createContext, ReactNode, useCallback, useContext, useEffect, useRef, useState } from 'react'
import {
  ActionCodeSettings,
  createUserWithEmailAndPassword,
  getRedirectResult,
  onAuthStateChanged,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithPopup,
  signInWithRedirect,
  signOut,
  User,
  UserCredential,
} from 'firebase/auth'
import { Credentials } from '../../app/types/auth'
import { firebase } from '../../app/config/firebase'
import { authRepository } from '../../app/api/authRepository'
import { restClient } from '../../app/datasource/remote/rest/restClient'
import { To } from '../../app/router/to'

type SignedOut = {
  user: null
  isSignedIn: false
}

type SignedIn = {
  user: User
  isSignedIn: true
}

type UserState = SignedOut | SignedIn

type Provider = keyof typeof firebase.authProviders

type SignInWithRedirectOptions = {
  provider: Provider
}

type Context = UserState & {
  isLoading: boolean
  signIn: (credentials: Credentials) => Promise<void>
  signUp: (credentials: Credentials) => Promise<void>
  signOut: () => Promise<void>
  signInWithPopup: (options: SignInWithRedirectOptions) => Promise<UserCredential | null>
  signInWithRedirect: (options: SignInWithRedirectOptions) => Promise<void>
  getRedirectResult: () => Promise<UserCredential | null>
  sendPasswordResetEmail: (credentials: Pick<Credentials, 'email'>) => Promise<void>
}

const AuthContext = createContext<Context>(null!)

export const useAuth = () => useContext(AuthContext)

const axiosInstance = restClient.instance

const getAccessToken = memoizee(async (user: User | null) => {
  if (!user) return null
  const token = await user.getIdToken()
  const result = await authRepository.getAccessToken({ token })
  return result.token
})

type Props = { children?: ReactNode }

export const AuthProvider = ({ children }: Props) => {
  const [user, setUser] = useState<User | null>(null)
  const [isLoading, setLoading] = useState(true)

  const isSignedIn = Boolean(user)

  const prevInterceptor = useRef<number | null>(null)

  const setAuthorizationHeaders = useCallback((accessToken: string | null) => {
    if (prevInterceptor.current != null) {
      axiosInstance.interceptors.request.eject(prevInterceptor.current)
    }

    const interceptor = axiosInstance.interceptors.request.use((config) => {
      if (config.headers) {
        const bearer = accessToken ? `Bearer ${accessToken}` : ''
        const token = accessToken ?? ''
        config.headers['Authorization'] = bearer
        config.headers['X-Access-Token'] = token
      }

      return config
    })

    prevInterceptor.current = interceptor
  }, [])

  const updateAccessToken = useCallback(
    async (user: User | null) => {
      const accessToken = await getAccessToken(user)
      setAuthorizationHeaders(accessToken)
      setUser(user)
      setLoading(false)
    },
    [setAuthorizationHeaders],
  )

  const _signIn = async ({ email, password }: Credentials) => {
    try {
      setLoading(true)
      await signInWithEmailAndPassword(firebase.auth, email, password)
    } catch (error) {
      setLoading(false)
      throw error
    }
  }

  const _signUp = async ({ email, password }: Credentials) => {
    try {
      setLoading(true)
      await createUserWithEmailAndPassword(firebase.auth, email, password)
    } catch (error) {
      setLoading(false)
      throw error
    }
  }

  const _signOut = async (): Promise<void> => {
    try {
      setLoading(true)
      await signOut(firebase.auth)
    } catch (error) {
      setLoading(false)
      throw error
    }
  }

  const _signInWithPopup = async (options: SignInWithRedirectOptions) => {
    const provider = firebase.authProviders[options.provider]
    const result = await signInWithPopup(firebase.auth, provider)
    return result
  }

  const _signInWithRedirect = async (options: SignInWithRedirectOptions) => {
    const provider = firebase.authProviders[options.provider]
    await signInWithRedirect(firebase.auth, provider)
  }

  const _getRedirectResult = () => {
    return getRedirectResult(firebase.auth)
  }

  const _sendPasswordResetEmail = ({ email }: Pick<Credentials, 'email'>) => {
    const actionCodeSettings: ActionCodeSettings = {
      url: new URL(To.signin, window.location.origin).toString(),
    }

    return sendPasswordResetEmail(firebase.auth, email, actionCodeSettings)
  }

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(firebase.auth, updateAccessToken)
    return () => unsubscribe()
  }, [updateAccessToken])

  const value = {
    auth: firebase.auth,
    isLoading,
    isSignedIn,
    user,
    signIn: _signIn,
    signUp: _signUp,
    signOut: _signOut,
    signInWithPopup: _signInWithPopup,
    signInWithRedirect: _signInWithRedirect,
    getRedirectResult: _getRedirectResult,
    sendPasswordResetEmail: _sendPasswordResetEmail,
  }

  return <AuthContext.Provider value={value as Context}>{children}</AuthContext.Provider>
}
