import React from 'react'
import { Stepper, Step, StepLabel, makeStyles, StepContent, Typography } from '@material-ui/core'

import { STEPS } from '../../app/utils/constants'

export const VerticalLinearStepper = () => {
  const classes = useStyles()

  return (
    <div>
      <Stepper orientation='vertical' className={classes.root}>
        {STEPS.map((step, index) => (
          <Step key={step.label} expanded={true} active={true}>
            <StepLabel>{step.label}</StepLabel>
            <StepContent>
              <Typography>{step.description}</Typography>
            </StepContent>
          </Step>
        ))}
      </Stepper>
    </div>
  )
}

const useStyles = makeStyles(() => ({
  root: {
    paddingLeft: 0,
    '& .MuiStepIcon-active': {
      color: '#8398E2',
      width: '11px',
      height: '11px',
    },
    '& .MuiStepIcon-completed': {
      color: '#8398E2',
      width: '11px',
      height: '11px',
    },
    '& .Mui-disabled .MuiStepIcon-root': {
      color: '#8398E2',
      width: '11px',
      height: '11px',
    },
    '& .MuiStepIcon-text': {
      display: 'none',
    },
    '& .MuiStepLabel-label': {
      color: '#8398E2',
      fontSize: '34px',
      fontWeight: 700,
      lineHeight: '35px',
      letterSpacing: '0.374px',
    },
    '& .MuiStepLabel-iconContainer': {
      paddingRight: 24,
    },
    '& .MuiStepContent-root': {
      borderLeft: '1px dashed rgba(0, 0, 0, 0.3)',
      marginLeft: 6,
      paddingLeft: 27,
      marginTop: 0,
    },
    '& .MuiStepConnector-root': {
      display: 'none',
    },
    '& .MuiCollapse-wrapperInner': {
      '& p': {
        fontSize: '15px',
        lineHeight: '24px',
        fontStyle: 'normal',
        color: '#111111',
        fontWeight: 400,
        letterSpacing: '-0.24px',
        marginBottom: 32,
      },
    },
  },
}))
