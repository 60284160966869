import React from 'react'
import cn from 'classnames'
import { alpha, createStyles, makeStyles, Typography } from '@material-ui/core'

type Props = {
  className?: string
  name: string
  extraText?: string
  price: string
}

export const TariffCard = ({ className, name, extraText, price }: Props) => {
  const classes = useStyles()

  return (
    <div className={cn(classes.root, className)}>
      <div className={classes.nameContainer}>
        <Typography className={classes.name}>{name}</Typography>
        {extraText && <Typography className={classes.extraText}>{extraText}</Typography>}
      </div>
      <div className={classes.priceContainer}>
        <Typography className={classes.price}>{price}</Typography>
      </div>
    </div>
  )
}

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: 'flex',
      borderRadius: theme.spacing(1.25),
      borderWidth: 2,
      borderStyle: 'solid',
      borderColor: '#8398e2',
      padding: theme.spacing(0.25),
      minHeight: 88,
    },
    nameContainer: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      padding: theme.spacing(3),
    },
    name: {
      marginBottom: theme.spacing(0.25),
      fontWeight: 700,
      fontSize: 17,
      lineHeight: '24px',
      color: '#111',
    },
    extraText: {
      fontWeight: 500,
      fontSize: 15,
      lineHeight: '16px',
      color: alpha(theme.palette.common.black, 0.75),
    },
    priceContainer: {
      flex: '0 0 28%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: theme.spacing(1),
      backgroundColor: '#8398e2',
    },
    price: {
      fontWeight: 700,
      fontSize: 20,
      lineHeight: '24px',
      color: theme.palette.common.white,
    },
  }),
)
