export enum To {
  main = '/',
  signin = '/signin',
  signup = '/signup',
  rate = '/rate',
  paymentFail = '/payment/fail',
  paymentSuccess = '/payment/success',
  passwordRecovery = '/password/email',
  passwordRecoveryInfo = '/password/info',
  publicOffer = '/offer',
  paymentAgreement = '/payment/agreement',
}
