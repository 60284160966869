import React, { FC } from 'react'
import { makeStyles, Typography } from '@material-ui/core'

export interface IStaticticItem {
  title: string
  description: string
}

export interface IStatisticBlockProps {
  element: IStaticticItem
}

export const StatisticBlock: FC<IStatisticBlockProps> = ({ element }) => {
  const classes = useStyles()

  const { title, description } = element

  return (
    <div className={classes.aboutStatisticBlock} key={title}>
      <Typography variant={'h2'}>{title}</Typography>
      <Typography variant={'body2'}>{description}</Typography>
    </div>
  )
}

const useStyles = makeStyles(() => ({
  aboutStatisticBlock: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    marginBottom: 24,
    '& h2': {
      marginBottom: 8,
    },
  },
}))
