import { RestResponse } from '../datasource/remote/rest/responseType'
import { restClient } from '../datasource/remote/rest/restClient'
import { Purchase, Rate } from '../types/rates'

class RatesRepository {
  getRates = async () => {
    const result = await restClient.get<RestResponse<Rate[]>>('/subscriptions/prices')
    return result.data
  }

  processPurchase = async ({ rateId, ...data }: Purchase) => {
    const result = await restClient.post<RestResponse<{ url: string }>>(`/subscriptions/init/${rateId}`, data)
    return result.data
  }
}

export const ratesRepository = new RatesRepository()
