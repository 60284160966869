import ErrorIcon from '@material-ui/icons/Error'
import { makeStyles, Theme, Typography, useTheme } from '@material-ui/core'
import { useNavigate } from 'react-router-dom'
import { BasicButton } from '../../app/components/button/button'
import { To } from '../../app/router/to'

export const PaymentFail = () => {
  const classes = useStyles()
  const theme = useTheme()
  const navigate = useNavigate()

  const navigateToHome = () => navigate(To.main)

  return (
    <div className={classes.root}>
      <div className={classes.status}>
        <ErrorIcon className={classes.icon} />
        <Typography variant={'h1'} align='center'>
          Оплата не прошла!
        </Typography>
      </div>
      <Typography className={classes.text} variant={'body1'} component={'p'} align={'center'}>
        Оплата не прошла, обратитесь в ваш банк или попробуйте другую карту.
      </Typography>
      <BasicButton
        className={classes.button}
        variant={'outlined'}
        color={theme.palette.text.accent}
        title={'Вернуться на главную'}
        onClick={navigateToHome}
      />
    </div>
  )
}

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    margin: '0 auto',
    padding: theme.spacing(5, 2, 2, 2),
    minHeight: '100vh',
    maxWidth: '390px',
  },
  status: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  icon: {
    color: theme.palette.error.light,
    fontSize: 128,
  },
  button: {
    margin: theme.spacing(2, 'auto', 0),
  },
  text: {
    fontWeight: 500,
    color: theme.palette.text.secondary,
  },
}))
