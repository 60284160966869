import React, { useRef } from 'react'
import cn from 'classnames'
import { alpha, createStyles, makeStyles, Typography, useTheme } from '@material-ui/core'
import { Carousel } from 'react-responsive-carousel'
import { ReviewsItem } from './ReviewsItem'

import 'react-responsive-carousel/lib/styles/carousel.min.css'

import review1 from '../../../../app/assets/icons/reviews/review1.png'
import review2 from '../../../../app/assets/icons/reviews/review2.png'
import review3 from '../../../../app/assets/icons/reviews/review3.png'
import review4 from '../../../../app/assets/icons/reviews/review4.png'
import review5 from '../../../../app/assets/icons/reviews/review5.png'
import review6 from '../../../../app/assets/icons/reviews/review6.png'
import review7 from '../../../../app/assets/icons/reviews/review7.png'
import review8 from '../../../../app/assets/icons/reviews/review8.png'
import review9 from '../../../../app/assets/icons/reviews/review9.png'
import review10 from '../../../../app/assets/icons/reviews/review10.png'

const REVIEWS = [
  { image: review1, text: '21 день питания по меню "Молния Z!", вес -3 кг, талия -5 см' },
  { image: review2, text: '21 день питания по меню "Молния Z!", вес -7,5 кг, талия -7 см' },
  { image: review3, text: '21 день питания по меню "Молния Z!", вес -4,8 кг, талия -6 см' },
  { image: review4, text: '21 день питания по меню "Молния Z!", вес -6,2 кг, талия -7 см' },
  { image: review5, text: '21 день питания по меню "Молния Z!", вес -6 кг, талия -5 см' },
  { image: review6, text: '21 день питания по меню "Молния Z!", вес -10 кг, талия -11 см' },
  { image: review7, text: '21 день питания по меню "Молния Z!", вес -7,3 кг, талия -8 см' },
  { image: review8, text: '21 день питания по меню "Молния Z!", вес -6 кг, талия -8 см' },
  { image: review9, text: '21 день питания по меню "Молния Z!", вес -7 кг, талия -12 см' },
  { image: review10, text: '21 день питания по меню "Молния Z!", вес -8 кг, талия -13 см' },
]

type Props = {
  className?: string
}

export const Reviews = ({ className }: Props) => {
  const theme = useTheme()
  const classes = useStyles()

  const carouselRef = useRef<Carousel | null>(null)

  const handleTouchStart = () => {
    carouselRef.current?.stopOnHover()
  }

  const handleTouchEnd = () => {
    carouselRef.current?.startOnLeave()
  }

  return (
    <div className={cn(classes.root, className)}>
      <Typography className={classes.title} variant={'h1'} component={'h2'} align={'center'}>
        Результаты марафона
      </Typography>
      <Typography className={classes.subtitle} align={'center'}>
        Данные результаты были получены на марафонах, где за основу было взято меню и расчеты из приложения «Молния Z!».
      </Typography>
      <Typography className={classes.subtitle} align={'center'}>
        Участники каждый день должны были питаться строго по меню приложения и вот результат!
      </Typography>
      <div onTouchStart={handleTouchStart} onTouchEnd={handleTouchEnd}>
        <Carousel
          className={classes.carousel}
          ref={carouselRef}
          interval={5000}
          swipeScrollTolerance={30}
          showArrows={false}
          showStatus={false}
          showThumbs={false}
          stopOnHover={true}
          emulateTouch
          swipeable
          autoPlay
          infiniteLoop
          preventMovementUntilSwipeScrollTolerance
          renderIndicator={(onClick, isSelected, index) => {
            return (
              <li
                style={{
                  display: 'inline-block',
                  margin: theme.spacing(0, 1.5),
                  borderRadius: '50%',
                  height: 11,
                  width: 11,
                  backgroundColor: isSelected ? theme.palette.pink.main : alpha('#818c99', 0.2),
                  cursor: 'pointer',
                }}
                value={index}
                key={index}
                role={'button'}
                tabIndex={0}
                onClick={onClick}
                onKeyDown={onClick}
              />
            )
          }}
        >
          {REVIEWS.map((review, index) => (
            <ReviewsItem key={index} image={review.image} alt={`Review ${index + 1}`} text={review.text} />
          ))}
        </Carousel>
      </div>
    </div>
  )
}

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      overflow: 'hidden',
    },
    title: {
      marginBottom: theme.spacing(5),
    },
    subtitle: {
      marginBottom: theme.spacing(3),
      padding: theme.spacing(0, 2),
      fontWeight: 400,
      fontSize: 15,
      lineHeight: '24px',
      color: alpha(theme.palette.common.black, 0.7),

      '&:last-of-type': {
        marginBottom: theme.spacing(2),
      },
    },
    carousel: {
      '& .carousel': {
        paddingBottom: theme.spacing(8),
      },
    },
  }),
)
