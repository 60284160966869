import { RestResponse } from '../datasource/remote/rest/responseType'
import { restClient } from '../datasource/remote/rest/restClient'
import { User } from '../types/user'

class AuthRepository {
  getAccessToken = async ({ token }: { token: string }) => {
    const data = { firebase: token }
    const result = await restClient.post<RestResponse<{ token: string }>>('/users/token', data)
    return result.data
  }

  me = async () => {
    const result = await restClient.get<RestResponse<User>>('/users/me')
    return result.data
  }
}

export const authRepository = new AuthRepository()
