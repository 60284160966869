import { makeStyles, Theme, useTheme } from '@material-ui/core/styles'
import { Formik, Form, FormikErrors } from 'formik'
import { AuthError } from 'firebase/auth'
import { FormInput } from '../../app/components/formik/input'
import { validateEmail } from '../../app/utils/validate'
import { BUTTON } from '../../app/utils/constants'
import { FormButton } from '../../app/components/formik/button'
import { ErrorBox } from '../../app/components/error/ErrorBox'
import { useSignInMutation } from '../auth/hooks/useSignInMutation'
import { Typography } from '@material-ui/core'
import { Link } from 'react-router-dom'
import { To } from '../../app/router/to'
import { BasicButton } from '../../app/components/button/button'
import { AppStoreSvg, GooglePlaySvg } from '../../app/assets/icons'

export type Props = {
  className?: string
  error?: string
  onSignInError?: (error: AuthError) => void
  onSignInSuccess?: () => void
  onSignInSettled?: () => void
  onSignInWithGoogle?: () => void
  onSignInWithApple?: () => void
}

export type FormValues = {
  email: string
  password: string
}

const initialValues: FormValues = { email: '', password: '' }

const validate = (values: FormValues) => {
  const errors: FormikErrors<FormValues> = {}

  if (!values.email) {
    errors.email = 'Required'
  } else if (validateEmail(values.email)) {
    errors.email = 'Invalid email'
  }

  if (!values.password) {
    errors.password = 'Required'
  }

  return errors
}

export const LoginForm = ({
  className,
  error,
  onSignInError: onError,
  onSignInSuccess: onSuccess,
  onSignInSettled: onSettled,
  onSignInWithGoogle,
  onSignInWithApple,
}: Props) => {
  const classes = useStyles()
  const theme = useTheme()

  const signInMutation = useSignInMutation()

  const onSubmit = async (values: FormValues) => {
    await signInMutation.mutateAsync(values, { onError, onSuccess, onSettled })
  }

  return (
    <div className={className}>
      <Formik initialValues={initialValues} validate={validate} onSubmit={onSubmit}>
        <Form className={classes.form}>
          <ErrorBox className={classes.error} open={Boolean(error)} error={error} />
          <FormInput id={'email'} label={'Введите ваш e-mail'} placeholder={'Введите ваш эл.адрес'} type={'text'} />
          <FormInput id={'password'} label={'Введите пароль'} placeholder={'Введите пароль'} type={'password'} />
          <FormButton
            title={BUTTON.signIn}
            type={'submit'}
            color={theme.palette.pink.main}
            textColor={theme.palette.pink.contrastText}
            fullWidth
            withoutBorder
          />
        </Form>
      </Formik>
      <div className={classes.sso}>
        <BasicButton
          type={'button'}
          title={'Google'}
          startIcon={<GooglePlaySvg />}
          variant={'outlined'}
          color={theme.palette.text.accent}
          fullWidth
          onClick={onSignInWithGoogle}
        />
        <BasicButton
          type={'button'}
          title={'Apple'}
          startIcon={<AppStoreSvg />}
          variant={'outlined'}
          color={theme.palette.text.accent}
          fullWidth
          onClick={onSignInWithApple}
        />
      </div>
      <div className={classes.helperText}>
        <Typography variant={'caption'} component={Link} to={To.passwordRecovery}>
          {'Восстановить пароль'}
        </Typography>
        <Typography variant={'caption'} component={Link} to={To.signup}>
          {'Зарегистрироваться'}
        </Typography>
      </div>
    </div>
  )
}

const useStyles = makeStyles<Theme>((theme) => ({
  form: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    marginBottom: 10,
  },
  error: {
    marginBottom: theme.spacing(2),
  },
  sso: {
    '& > *': {
      marginBottom: theme.spacing(1),
    },

    '&:last-of-child': {
      marginBottom: 0,
    },
  },
  helperText: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: theme.spacing(2),
  },
}))
