import React, { FC } from 'react'
import { makeStyles, Typography } from '@material-ui/core'
import { ABOUT_PAGE } from '../../../../app/utils/constants'

export const InfoBlock: FC = () => {
  const classes = useStyles()

  return (
    <div className={classes.infoBlock}>
      <Typography variant={'body1'}>{ABOUT_PAGE.description}</Typography>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  infoBlock: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 24,
    borderRadius: 5,
    padding: theme.spacing(3, 1.5),
    background: '#8398E2',

    '& p': {
      color: '#FFFF',
      textAlign: 'center',
    },
  },
}))
