import config from '../../app/config/config'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import { makeStyles, Theme, Typography, useTheme } from '@material-ui/core'
import { useNavigate } from 'react-router-dom'
import { BasicButton } from '../../app/components/button/button'
import { To } from '../../app/router/to'

export const PaymentSuccess = () => {
  const classes = useStyles()
  const theme = useTheme()
  const navigate = useNavigate()

  const navigateToHome = () => navigate(To.main)

  return (
    <div className={classes.root}>
      <div className={classes.status}>
        <CheckCircleIcon className={classes.icon} />
        <Typography variant={'h1'} align='center'>
          Оплата прошла успешно!
        </Typography>
      </div>
      <ul className={classes.list}>
        <Typography className={classes.text} variant={'body2'} component={'li'} align={'justify'}>
          Скачать приложение «Молния Z!» можно по ссылкам:{' '}
          <Typography
            className={classes.link}
            variant={'body1'}
            component={'a'}
            href={config.googleAppUrl}
            target={'_blank'}
          >
            Play Market
          </Typography>
          {', '}
          <Typography
            className={classes.link}
            variant={'body1'}
            component={'a'}
            href={config.appleAppUrl}
            target={'_blank'}
          >
            App Store
          </Typography>
          . После чего введите тот же логин и пароль и начинайте пользоваться!
        </Typography>
        <Typography className={classes.text} variant={'body2'} component={'li'} align={'justify'}>
          Если приложение «Молния Z!» у вас уже установлено, просто начинайте пользоваться.
        </Typography>
      </ul>
      <Typography variant={'body2'} component={'p'} align={'justify'}>
        Для авторизации в приложении используйте ваш Apple ID , Google Аккаунт, либо же введите логин и пароль.
      </Typography>
      <BasicButton
        className={classes.button}
        variant={'outlined'}
        color={theme.palette.text.accent}
        title={'Вернуться на главную'}
        onClick={navigateToHome}
      />
    </div>
  )
}

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    margin: '0 auto',
    padding: theme.spacing(5, 2, 2, 2),
    minHeight: '100vh',
    maxWidth: '390px',
  },
  status: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  icon: {
    color: theme.palette.success.main,
    fontSize: 128,
  },
  button: {
    margin: theme.spacing(2, 'auto', 0),
  },
  list: {
    margin: theme.spacing(2, 0),
    paddingLeft: theme.spacing(2),

    '& > li': {
      marginBottom: theme.spacing(1),
    },

    '& > li:last-of-type': {
      marginBottom: theme.spacing(0),
    },
  },
  text: {
    fontWeight: 500,
    color: theme.palette.text.secondary,
  },
  link: {
    fontWeight: 500,
    color: theme.palette.text.accent,
    textDecoration: 'none',
  },
}))
