import { useMutation } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { ratesRepository } from '../../../app/api/ratesRepository'
import { FirstParameter, Unwrap } from '../../../app/types/common'
import { RestError } from '../../../app/types/rest'

type Error = AxiosError<RestError>
type Response = Unwrap<ReturnType<typeof ratesRepository.processPurchase>>
type Variables = FirstParameter<typeof ratesRepository.processPurchase>

export const useProcessPurchaseMutation = () => {
  return useMutation<Response, Error, Variables>(ratesRepository.processPurchase)
}
