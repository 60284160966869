import { CSSProperties } from '@material-ui/core/styles/withStyles'

import SFProRegular from '../assets/fonts/SFProDisplay-Regular.woff2'
import SFProLight from '../assets/fonts/SFProDisplay-Light.woff2'
import SFProSemiBold from '../assets/fonts/SFProDisplay-Semibold.woff2'
import SFProMedium from '../assets/fonts/SFProDisplay-Medium.woff2'
import SFProBold from '../assets/fonts/SFProDisplay-Bold.woff2'

export const fontFace: CSSProperties['@font-face'] = [
  {
    fontFamily: 'SF Pro Display',
    fontDisplay: 'swap',
    fontWeight: 300,
    src: `
      local('SF Pro Display'),
      local('SF-Pro-Display-Light'),
      url(${SFProLight}) format('woff2')
    `,
  },
  {
    fontFamily: 'SF Pro Display',
    fontDisplay: 'swap',
    fontWeight: 400,
    fontStyle: 'semi-bold',
    src: `
      local('SF Pro Display'),
      local('SF-Pro-Display-Regular'),
      url(${SFProRegular}) format('woff2')
    `,
  },
  {
    fontFamily: 'SF Pro Display',
    fontDisplay: 'swap',
    fontWeight: 500,
    src: `
      local('SF Pro Display'),
      local('SF-Pro-Display-Medium'),
      url(${SFProMedium}) format('woff2')
    `,
  },
  {
    fontFamily: 'SF Pro Display',
    fontDisplay: 'swap',
    fontWeight: 600,
    src: `
      local('SF Pro Display'),
      local('SF-Pro-Display-Semibold'),
      url(${SFProSemiBold}) format('woff2')
    `,
  },
  {
    fontFamily: 'SF Pro Display',
    fontDisplay: 'swap',
    fontWeight: 700,
    src: `
      local('SF Pro Display'),
      local('SF-Pro-Display-Semibold'),
      url(${SFProBold}) format('woff2')
    `,
  },
]
