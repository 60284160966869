import React, { ComponentProps } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Field } from 'formik'

type Props = ComponentProps<typeof Field> & {
  label: string
  id: string
  placeholder: string
}

export const FormInput = ({ id, label, placeholder, ...props }: Props) => {
  const classes = useStyles()

  return (
    <>
      <label className={classes.label} htmlFor={id}>
        {label}
      </label>
      <Field {...props} id={id} className={classes.input} name={id} placeholder={placeholder} />
    </>
  )
}

const useStyles = makeStyles(() => ({
  label: {
    fontSize: '20px',
    lineHeight: '24px',
    fontStyle: 'normal',
    letterSpacing: '0.38px',
    color: '#111111',
    fontWeight: 400,
    marginBottom: 8,
  },
  input: {
    minHeight: '54px',
    border: '1px solid #818C99',
    borderRadius: 5,
    opacity: 0.7,
    paddingLeft: 16,
    color: '#818C99',
    fontSize: '17px',
    lineHeight: '22px',
    letterSpacing: '-0.408px',
    marginBottom: 22,
  },
}))
