import React, { ComponentPropsWithoutRef } from 'react'
import cn from 'classnames'
import { makeStyles, Typography } from '@material-ui/core'
import { RadioButton } from '../radioButton/radioButton'

type Props = Omit<ComponentPropsWithoutRef<typeof RadioButton>, 'label'> & {
  label: string
  helperText?: string
  text?: string
}

export const BlockWithRadio = ({ label, helperText, text, ...props }: Props) => {
  const classes = useStyles()

  const box = (
    <div className={classes.container}>
      <div className={classes.labelContainer}>
        <Typography className={classes.label} variant={'body1'} component={'span'}>
          {label}
        </Typography>
        {helperText && (
          <Typography variant={'body2'} component={'span'}>
            {helperText}
          </Typography>
        )}
      </div>
      <Typography variant={'h1'} component={'span'}>
        {text}
      </Typography>
    </div>
  )

  const style = props.checked ? { border: '2px solid #FFF0DC' } : {}

  return (
    <RadioButton
      {...props}
      className={cn(classes.root, props.className)}
      style={{ ...style, ...props.style }}
      label={box}
    />
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
    margin: 0,
    padding: theme.spacing(2, 1),
    border: '2px solid #EAE3F1',
    borderRadius: 8,
    minHeight: 84,
  },
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: theme.spacing(2),
  },
  labelContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  label: {
    marginBottom: theme.spacing(0.5),
    fontWeight: 700,
    fontSize: '22px',
    lineHeight: '28px',
    color: '#2C2D2E',
    letterSpacing: '0.35px',
  },
}))
