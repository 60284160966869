import React, { FC, useMemo } from 'react'
import { makeStyles, Typography } from '@material-ui/core'
import { IStaticticItem, StatisticBlock } from './statisticBlock'
import { InfoBlock } from './infoBlock'
import { DownloadBlock } from './downloadBlock'
import { Footer } from './footer'
import { ABOUT_PAGE, MOCK_STATISTIC } from '../../../../app/utils/constants'
import { useAuth } from 'features/auth/AuthProvider'

export const AboutPage: FC = () => {
  const classes = useStyles()
  const auth = useAuth()

  const statistic = useMemo(() => {
    return MOCK_STATISTIC
  }, [])

  return (
    <div className={classes.about}>
      <Typography variant={'h1'}>{ABOUT_PAGE.header}</Typography>
      <div className={classes.aboutStatistic}>
        {statistic.map((element: IStaticticItem, index: number) => (
          <StatisticBlock element={element} key={element.title + index} />
        ))}
      </div>
      <InfoBlock />
      {auth.isSignedIn && <DownloadBlock />}
      <Footer />
    </div>
  )
}

const useStyles = makeStyles(() => ({
  about: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  aboutStatistic: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    marginTop: 40,
    marginBottom: 16,
  },
}))
