import { makeStyles, Typography } from '@material-ui/core'
import React from 'react'

export const PaymentAgreementPage = () => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Typography className={classes.header} variant={'h1'} align={'center'}>
        СОГЛАШЕНИЕ НА СОВЕРШЕНИЕ РЕКУРРЕНТНЫХ ПЛАТЕЖЕЙ
      </Typography>
      <Typography className={classes.header} variant={'h2'} align={'center'}>
        (Дополнительное соглашение к публичной оферте, размещенной по адресу https://landing.zipperz.ru/offer)
      </Typography>
      <Typography variant={'body1'} align={'justify'}>
        С целью проведения взаиморасчетов в соответствии с публичной офертой размещенной по адресу
        https://landing.zipperz.ru/offer (далее – «<strong>Оферта</strong>») Вы (далее – «<strong>Пользователь</strong>
        ») принимаете условия настоящего соглашения, заключаемого с Индивидуальным предпринимателем Шахиджановой
        Анастасией Владиславовной (далее – «<strong>Администрация</strong>») на совершение рекуррентных платежей (далее
        – «<strong>Соглашение</strong>»), тем самым подтверждаете факт ознакомления и полного безоговорочного согласие с
        её условиями. В случае, если Вы согласны с условиями данного Соглашения, Вам будет предоставлен полный доступ к
        мобильному приложению «Молния Z!» (далее – «<strong>Сервис</strong>»),а именно:
      </Typography>
      <ul>
        <Typography variant={'body1'} component={'li'} align={'justify'}>
          доступ к Профилю, с возможностью редактировать вес, рост, возраст, пол, цель, схему питания, норму калорий и
          воды;
        </Typography>
        <Typography variant={'body1'} component={'li'} align={'justify'}>
          доступ к Марафону с возможностью создания и редактирования индивидуального марафона по выбранной схеме питания
          с загрузкой блюд с рецептами, фото и индивидуальным расчётом КБЖУ;
        </Typography>
        <Typography variant={'body1'} component={'li'} align={'justify'}>
          доступ к Результатам где Пользователь имеет возможность видеть результаты собственного марафона;
        </Typography>
        <Typography variant={'body1'} component={'li'} align={'justify'}>
          доступ к информации о представленных схемах питания;
        </Typography>
        <Typography variant={'body1'} component={'li'} align={'justify'}>
          доступ к Помощнику который на основе опроса рекомендует Пользователю оптимальную схему питания;
        </Typography>
        <Typography variant={'body1'} component={'li'} align={'justify'}>
          доступ к истории потребления калорий и воды;
        </Typography>
        <Typography variant={'body1'} component={'li'} align={'justify'}>
          доступ ко всем формулам и расчётам реализованным в мобильном приложении;
        </Typography>
        <Typography variant={'body1'} component={'li'} align={'justify'}>
          доступ к базе данных продуктов с возможностью добавления собственного продукта;
        </Typography>
        <Typography variant={'body1'} component={'li'} align={'justify'}>
          доступ ко всем блюдам находящимся в базе данных с возможностью их замены;
        </Typography>
        <Typography variant={'body1'} component={'li'} align={'justify'}>
          иной функционал Сервиса, определяемый Администрацией;
        </Typography>
      </ul>
      <ol>
        <Typography variant={'body1'} component={'li'} align={'justify'}>
          С целью получения доступа к полному функционалу Сервиса Пользователь оплачивает Администрации вознаграждение,
          определяемое в соответствии с принятыми тарифами (далее – «<strong>Тарифы</strong>»).
        </Typography>
        <Typography variant={'body1'} component={'li'} align={'justify'}>
          Каждый новый Пользователь, оплатив 100 % стоимости по выбранному Тарифу приобретает доступ к Сервису на
          период, в соответствии с условиями Тарифа опубликованного на сайте http://zipperz.ru.
        </Typography>
        <Typography variant={'body1'} component={'li'} align={'justify'}>
          Доступ к Сервису оказывается сразу после поступления денег от Пользователя на расчетный счёт Администрации, но
          не позднее 2 (двух) календарных дней с момента успешной оплаты.
        </Typography>
        <Typography variant={'body1'} component={'li'} align={'justify'}>
          После оплаты Тарифа согласно п. 2 настоящего Соглашения Пользователь предоставляет Администрации право
          сохранить учетные данные для будущих транзакцией в профиле пользователя на условиях Оферты размещенной по
          адресу https://landing.zipperz.ru/offer.
        </Typography>
        <Typography variant={'body1'} component={'li'} align={'justify'}>
          После проведения первой оплаты Пользователю предоставляется услуга автоплатежа, от которой Пользователь вправе
          в любой момент отказаться посредством функционала Сервиса, как посредством обращения в техподдержку,
          соответственная ссылка присутствует на сайте http://zipperz.ru , так и с помощью кнопки «Управление подпиской»
          реализованной в мобильном приложении «Молния Z!». До момента отказа Пользователя денежные средства,
          предназначенные для оплаты доступа к выбранному тарифу на следующий период, будут списываться со счета карты
          Пользователя, привязанной к его профилю, в размере стоимости Тарифа автоматически (без дополнительного
          подтверждения со стороны Пользователя) за 1 (один) календарный день до даты истечения срока действия
          предыдущего оплаченного периода. В случае, если размер денежных средств на счете банковской карты
          Пользователя, привязанной к Профилю, окажется недостаточной доступ к Сервису приостанавливается.
        </Typography>
        <Typography variant={'body1'} component={'li'} align={'justify'}>
          Стоимость Тарифов устанавливается Администрацией и размещается на Сайте Сервиса и в мобильном приложении.
        </Typography>
        <Typography variant={'body1'} component={'li'} align={'justify'}>
          Платежи (транзакции) производятся в российских рублях. Указанная сумма оплаты по выбранному Пользователем
          Тарифу является окончательной и включает в себя все налоги и издержки Администрации по получению оплаты и
          исполнению принятых на себя обязательств.
        </Typography>
        <Typography variant={'body1'} component={'li'} align={'justify'}>
          Администрация в одностороннем порядке устанавливает размер и содержание Тарифов. Тарифы могут быть изменены
          Администрацией в любое время без какого-либо специального уведомления об этом Пользователя. Размер новых
          тарифов вступает в силу с момента истечения срока действия оплаченного ранее Тарифа.
        </Typography>
        <Typography variant={'body1'} component={'li'} align={'justify'}>
          Денежные средства, оплаченные Администрации за доступ к Сервису, после предоставления доступа не подлежат
          возврату. Доступ считается предоставленным с момента получения Пользователем технической возможности
          использовать Сервис согласно п. 2,3 настоящего Соглашения, и не зависит от фактического его использования.
          Пересчет либо возврат оплаченных денежных средств за предоставленный согласно Тарифам, доступ не производится,
          доступ сохраняется до конца оплаченного периода.
        </Typography>
        <Typography variant={'body1'} component={'li'} align={'justify'}>
          Соглашение вступает в силу с момента его акцепта Пользователем и действует до даты принятия новой редакции
          Соглашения. В случае возникновения спора между Администраций и Пользователем, связанного с исполнением
          Соглашения, он разрешается путем договоренности Сторон или, при невозможности достижения согласия, он
          разрешается в соответствии с действующим законодательством Российской Федерации в суде по месту нахождения
          Администрации. Применимым правом является право Российской Федерации. До обращения в соответствующий суд
          заинтересованная Сторона направляет другой Стороне претензию, срок ответа на которую устанавливается 30
          (тридцать) календарных дней с момента её получения.
        </Typography>
      </ol>
      <Typography className={classes.header} variant={'h2'} align={'center'}>
        Владелец Сервиса:
      </Typography>
      <Typography variant={'body1'}>
        ИНДИВИДУАЛЬНЫЙ ПРЕДПРИНИМАТЕЛЬ ШАХИДЖАНОВА АНАСТАСИЯ ВЛАДИСЛАВОВНАИНН: 213003811759
      </Typography>
      <Typography variant={'body1'}>ОГРН: 320508100368411</Typography>
      <Typography variant={'body1'}>Адрес электронной почты: Zipperz.marketing@yandex.ru</Typography>
      <Typography variant={'body1'}>Сайт: http://zipperz.ru</Typography>

      <Typography className={classes.header} variant={'h1'} align={'center'}>
        СОГЛАСИЕ НА СОХРАНЕНИЕ УЧЕТНЫХ ДАННЫХ ДЛЯ БУДУЩИХ ТРАНЗАКЦИЙ
      </Typography>
      <Typography variant={'body1'} align={'justify'}>
        Я, дееспособное лицо, достигшее восемнадцатилетнего возраста, именуемому в дальнейшем «Заказчик», свободно,
        своей волей и в своем интересе используя интернет-ресурс, размещенный на сайте по адресу: http://zipperz.ru
        (включая все уровни указанного домена, как функционирующие на дату предоставления Согласия, так и запускаемые, и
        вводимые в эксплуатацию в течение всего срока его действия, далее «<strong>Сайт</strong>») в соответствии со
        ст.9 Федерального закона от 27.07.06 г. «О персональных данных» № 152-ФЗ, со ст. 6 Федерального закона от
        27.06.2011 №161-ФЗ «О национальной платежной системе», со ст. 6 Федерального закона от 27.06.2011 №161-ФЗ «О
        национальной платежной системе» предоставляю индивидуальному предпринимателю Шахиджановой Анастасии
        Владиславовне ( ИНН: 213003811759 , ОГРН: 320508100368411, далее – «<strong>Исполнитель</strong>») согласие на
        хранение учетных данных банковской карты на следующих условиях:
      </Typography>
      <ol>
        <Typography variant={'body1'} component={'li'} align={'justify'}>
          Исполнитель получает и передает от Заказчика для хранения учетные данные банковской карты в целях исполнения
          договора по средствам принятия Публичной оферты, размещенной по адресу http://zipperz.ru и Соглашением на
          совершение рекуррентных платежей размещенным по адресу http://zipperz.ru оператору платежной системы АО
          «Тинькофф Банк» (Акционерное общество «Тинькофф Банк», БИК 044525974, ИНН/КПП 7710140679/771301001, Основной
          регистрационный номер 1027739642281 сайт https://www.tinkoff.ru/, далее – «Оператор»).
        </Typography>
        <Typography variant={'body1'} component={'li'} align={'justify'}>
          Перечень данных, получаемых Исполнителем и передаваемых Оператору для хранения:
          <ul>
            <Typography variant={'body1'} component={'li'} align={'justify'}>
              Наименования банка, держателя банковской карты (счета);
            </Typography>
            <Typography variant={'body1'} component={'li'} align={'justify'}>
              БИК банка, держателя банковской карты (счета);
            </Typography>
            <Typography variant={'body1'} component={'li'} align={'justify'}>
              Номер корреспондентского счета банка, держателя банковской карты (счета);
            </Typography>
            <Typography variant={'body1'} component={'li'} align={'justify'}>
              Номер банковской карты;
            </Typography>
            <Typography variant={'body1'} component={'li'} align={'justify'}>
              Срок действия банковской карты;
            </Typography>
            <Typography variant={'body1'} component={'li'} align={'justify'}>
              CVV-код банковской карты.
            </Typography>
          </ul>
        </Typography>
        <Typography variant={'body1'} component={'li'} align={'justify'}>
          После ввода Заказчиком требуемых банковских реквизитов, Заказчик полностью соглашается с условиями настоящего
          согласия и уполномочивает Исполнителя хранить учетные данные Заказчика, в том числе сокращенную версию
          сохраненных учетных данных (например, последние четыре цифры кредитной карты). А также Заказчик дает свое
          Согласие на хранение учетных данных для будущих транзакций.
        </Typography>
        <Typography variant={'body1'} component={'li'} align={'justify'}>
          Я подтверждаю, что ознакомился и согласен с Политикой в отношении обработки персональных данных принятой
          Администрацией и размещенной по адресу http://zipperz.ru , а также предоставил Согласие на такую обработку
          размещенное по адресу http://zipperz.ru.
        </Typography>
        <Typography variant={'body1'} component={'li'} align={'justify'}>
          Учетные данные Заказчика будут использованы для проведение транзакции с использованием сохраненных учетных
          данных, инициированные Исполнителем.
        </Typography>
        <Typography variant={'body1'} component={'li'} align={'justify'}>
          Фиксированная дата и периодичность транзакции определяются в зависимости от тарифа выбранного Заказчиком и
          размещенном на сайте: начиная с момента присоединения к публичной оферте Исполнителя.
        </Typography>
        <Typography variant={'body1'} component={'li'} align={'justify'}>
          Событие, которое послужит причиной транзакции: платежный день, который определяется в зависимости от тарифа,
          выбранного Заказчиком, начиная с момента присоединения к публичной оферте Исполнителя.
        </Typography>
        <Typography variant={'body1'} component={'li'} align={'justify'}>
          Исполнитель уведомляет о каких-либо изменениях данного соглашения за 7 рабочих дней до того, как такие
          изменения вступают в силу касательно инициированных Исполнителем транзакций с использованием сохраненных
          учетных данных, которые происходят с установленной, регулярной периодичностью. Направляет информационные
          сообщения Заказчику, содержащие информацию о всех изменениях, посредством электронных сообщений по адресу
          электронной почты указанному Заказчиком при регистрации.
        </Typography>
        <Typography variant={'body1'} component={'li'} align={'justify'}>
          Заказчик обязуется уведомлять Исполнителя о каких-либо изменениях не позднее 7 рабочих дней до следующей даты
          транзакций.
        </Typography>
        <Typography variant={'body1'} component={'li'} align={'justify'}>
          Все претензии Заказчиков рассматриваются в течение 30 (тридцати) рабочих дней со дня их получения. Исполнитель
          считает ответ Заказчику совершенным надлежащим образом при направлении электронного письма на адрес, с
          которого пришла претензия.
        </Typography>
        <Typography variant={'body1'} component={'li'} align={'justify'}>
          Настоящее согласие действует с момента его предоставления до момента расторжения договора между субъектом,
          предоставившим настоящее согласие или до момента отказа от проведения рекуррентных платежей с использованием
          функционала онлайн-сервиса http://zipperz.ru, доступ осуществляется через Сайт.
        </Typography>
      </ol>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    maxWidth: 390,
    margin: '0 auto',
    padding: theme.spacing(5, 2),

    '& ol': {
      paddingInlineStart: theme.spacing(2),
      listStyle: 'none',
      counterReset: 'list-item-number',
    },
    '& ul': {
      paddingInlineStart: theme.spacing(2),
    },
    '& ol > li': {
      counterIncrement: 'list-item-number',
    },
    '& ol > li:before': {
      content: 'counters(list-item-number, ".") ". "',
    },
  },
  header: {
    marginBottom: theme.spacing(2),
    color: theme.palette.common.black,
  },
}))
