import React from 'react'
import ReactDOM from 'react-dom/client'
import { YMInitializer } from 'react-yandex-metrika'
import { App } from './App'
import reportWebVitals from './reportWebVitals'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <YMInitializer
      accounts={[90523041]}
      options={{
        webvisor: true,
        clickmap: true,
        trackLinks: true,
        accurateTrackBounce: true,
        trackHash: true,
      }}
      version='2'
    />
    <App />
  </React.StrictMode>,
)

reportWebVitals()
