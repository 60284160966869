import React, { FC } from 'react'
import { makeStyles, Typography } from '@material-ui/core'
import { ImageWithLoader } from '../../../../app/components/imageWithLoader/imageWithLoader'

export interface ICarouselItem {
  id: string | number
  src: string
  title: string
}

export interface ICarouselItemProps {
  item: ICarouselItem
}

export const CarouselItem: FC<ICarouselItemProps> = ({ item }) => {
  const classes = useStyles()

  const { id, title } = item

  return (
    <div key={id} className={classes.item}>
      <ImageWithLoader item={item} />
      {/* <img src={src} className={classes.image} alt={title} /> */}
      <div className={classes.text}>
        <Typography variant={'body1'}>{title}</Typography>
      </div>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  item: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: theme.spacing(3),
  },
  text: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: theme.spacing(2.5, 0, 3),
    padding: theme.spacing(0, 3),
  },
}))
