import React, { FC } from 'react'
import { makeStyles, Typography } from '@material-ui/core'
import { ABOUT_PAGE } from '../../../../app/utils/constants'

export const Footer: FC = () => {
  const classes = useStyles()

  return (
    <div>
      <Typography className={classes.text} variant={'body1'} align={'center'} gutterBottom>
        {ABOUT_PAGE.footer}
      </Typography>
      <Typography className={classes.text} variant={'body1'} align={'center'}>
        ИП Шахиджанова Анастасия Владиславовна
      </Typography>
      <Typography className={classes.text} variant={'body1'} align={'center'}>
        ИНН: 213003811759
      </Typography>
      <Typography className={classes.text} variant={'body1'} align={'center'}>
        ОГРН: 320508100368411
      </Typography>
    </div>
  )
}

const useStyles = makeStyles(() => ({
  text: {
    fontSize: '12px',
    lineHeight: '120%',
    fontStyle: 'normal',
    color: '#111111',
    fontWeight: 400,
    letterSpacing: '0.374px',
    opacity: 0.6,
  },
}))
