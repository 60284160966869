import { useMutation } from '@tanstack/react-query'
import { AuthError } from 'firebase/auth'
import { Unwrap } from '../../../app/types/common'
import { useAuth } from '../AuthProvider'

type Auth = ReturnType<typeof useAuth>
type Response = Unwrap<ReturnType<Auth['signOut']>>
type Error = AuthError

export const useSignOutMutation = () => {
  const { signOut } = useAuth()
  return useMutation<Response, Error>(signOut)
}
