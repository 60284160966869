import React, { ComponentPropsWithoutRef } from 'react'
import cn from 'classnames'
import { alpha, Button, ButtonProps, CircularProgress, makeStyles, Theme, useTheme } from '@material-ui/core'

type Props<T extends React.ElementType> = Omit<ButtonProps, 'color'> &
  ComponentPropsWithoutRef<T> & {
    color?: string
    component?: T
    loading?: boolean
    textColor?: string
    title?: string
    uppercase?: boolean
    withoutBorder?: boolean
  }

export const BasicButton = <T extends React.ElementType>(props: Props<T>) => {
  const {
    children,
    className,
    color,
    disabled,
    loading = false,
    startIcon,
    textColor,
    title,
    uppercase = false,
    variant = 'contained',
    withoutBorder = false,
    ...other
  } = props
  const classes = useStyles(props)
  const theme = useTheme()

  return (
    <Button
      {...other}
      className={cn(classes.root, className)}
      style={{
        ...(variant === 'outlined'
          ? {
              borderColor: color ?? theme.palette.text.primary,
              backgroundColor: theme.palette.common.white,
              color: textColor ?? color ?? theme.palette.text.primary,
            }
          : {}),
        ...(variant === 'contained'
          ? {
              borderColor: theme.palette.common.white,
              backgroundColor: color ?? theme.palette.common.white,
              color: textColor ?? theme.palette.text.primary,
            }
          : {}),
        ...(disabled
          ? {
              borderColor: theme.palette.grey[200],
              backgroundColor: theme.palette.grey[300],
              color: alpha(theme.palette.text.primary, 0.65),
            }
          : {}),
        ...(uppercase ? { textTransform: 'uppercase' } : { textTransform: 'none' }),
        ...(withoutBorder ? { border: 'none' } : {}),
      }}
      startIcon={loading ? <CircularProgress size={24} /> : startIcon}
      variant={variant}
      disabled={disabled || loading}
    >
      {title ? title : children}
    </Button>
  )
}

// Props functions of makeStyles doesn't work in React 18
const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    minHeight: 56,
    borderWidth: 2,
    borderStyle: 'solid',
    borderRadius: theme.spacing(1.25),
    backdropFilter: 'blur(80px)',
  },
}))
