import React, { ComponentProps } from 'react'
import { BasicButton } from '../button/button'
import { useFormikContext } from 'formik'

type Props = ComponentProps<typeof BasicButton>

export const FormButton = (props: Props) => {
  const form = useFormikContext()

  const isSubmitButton = props.type === 'submit'

  const isLoading = isSubmitButton && form.isSubmitting

  const isDisabled = props.disabled || !form.isValid

  return <BasicButton {...props} loading={isLoading} disabled={isDisabled} />
}
