import { FC, useState } from 'react'
import { alpha, makeStyles, Theme, useTheme } from '@material-ui/core/styles'
import { FormControl, RadioGroup, Typography } from '@material-ui/core'
import { BasicButton } from '../../app/components/button/button'
import { OfferAgreement } from '../../app/components/offer/offer'
import { BUTTON, RATE_PAGE } from '../../app/utils/constants'
import { useRatesQuery } from './hooks/useRatesQuery'
import { useMeQuery } from '../auth/hooks/useMeQuery'
import { BlockWithRadio } from '../../app/components/blockWithRadio/blockWithRadio'
import { useProcessPurchaseMutation } from './hooks/useProcessPurchaseMutation'
import { RateSkeleton } from './RateSkeleton'
import { To } from '../../app/router/to'
import { formatRateMonths } from 'app/utils/dates'

interface IRatePageProps {
  header?: string
  helperText?: string
}

const getColorByIndex = (theme: Theme) => (index: number) => {
  switch (index % 3) {
    default:
    case 0:
      return alpha(theme.palette.yellow.main, 0.5)
    case 1:
      return alpha(theme.palette.pink.main, 0.5)
    case 2:
      return alpha(theme.palette.blue.main, 0.5)
  }
}

const priceFormatter = new Intl.NumberFormat('ru-Ru', {
  style: 'currency',
  currency: 'RUB',
  maximumFractionDigits: 0,
})

export const RatePage: FC<IRatePageProps> = ({ header, helperText }) => {
  const classes = useStyles()
  const theme = useTheme()
  const getColor = getColorByIndex(theme)

  const meQuery = useMeQuery()
  const ratesQuery = useRatesQuery()

  const processPurchaseMutation = useProcessPurchaseMutation()

  const [rateId, setRateId] = useState<number | null>(null)

  const handleChange = (_: unknown, value: string) => {
    setRateId(parseInt(value))
  }

  const handleSubmit = async () => {
    if (meQuery.data && rateId) {
      const origin = window.location.origin
      const email = meQuery.data.email
      const successUrl = new URL(To.paymentSuccess, origin).toString()
      const failUrl = new URL(To.paymentFail, origin).toString()

      await processPurchaseMutation.mutateAsync(
        { rateId, email, successUrl, failUrl },
        {
          onSuccess: ({ url }) => {
            window.location.href = url
          },
        },
      )
    }
  }

  return (
    <div className={classes.root}>
      <Typography variant={'h1'} style={helperText ? { marginBottom: 24 } : { marginBottom: 40 }}>
        {header ?? RATE_PAGE.newUser.header}
      </Typography>
      {helperText && <Typography className={classes.helperText}>{helperText}</Typography>}

      {ratesQuery.isLoading && <RateSkeleton className={classes.skeleton} count={3} />}

      {ratesQuery.isSuccess && (
        <FormControl component='fieldset'>
          <RadioGroup aria-label='rate' name='rate' value={`${rateId}` ?? ''} onChange={handleChange}>
            {ratesQuery.data.map((rate, index) => (
              <BlockWithRadio
                className={classes.checkbox}
                style={{ backgroundColor: getColor(index) }}
                key={rate.id}
                value={`${rate.id}`}
                label={formatRateMonths(rate.months)}
                text={priceFormatter.format(rate.price)}
                helperText={rate.benefit}
                checked={`${rateId}` === `${rate.id}`}
              />
            ))}
          </RadioGroup>
        </FormControl>
      )}
      <OfferAgreement />
      <BasicButton
        className={classes.button}
        title={BUTTON.pay}
        color={theme.palette.pink.main}
        textColor={theme.palette.pink.contrastText}
        disabled={!rateId}
        loading={processPurchaseMutation.isLoading}
        withoutBorder
        onClick={handleSubmit}
      />
      <Typography className={classes.text} variant={'body1'} align={'center'}>
        Отменить можно в любой момент
      </Typography>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    maxWidth: '390px',
    margin: '0 auto',
    padding: theme.spacing(5, 2, 2),
  },
  checkbox: {
    marginBottom: theme.spacing(2),
  },
  helperText: {
    color: '#818C99',
    fontSize: '20px',
    lineHeight: '24px',
    letterSpacing: '0.38px',
    fontWeight: 400,
    marginBottom: 40,
  },
  button: {
    marginBottom: 10,
    marginTop: 24,
  },
  skeleton: {
    marginBottom: theme.spacing(2),
  },
  text: {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '11px',
    lineHeight: '14px',
    color: alpha('#818C99', 0.6),
    letterSpacing: '0.066px',
  },
}))
