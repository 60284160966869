import React from 'react'
import cn from 'classnames'
import { alpha, Box, makeStyles, Theme, Typography } from '@material-ui/core'

type Props = {
  className?: string
  error?: string
  open: boolean
  onClose?: () => void
}

export const ErrorBox = ({ className, error, open }: Props) => {
  const classes = useStyles()

  if (!open) return null

  return (
    <Box className={cn(classes.root, className)}>
      <Typography variant={'body1'} component={'p'} color={'error'}>
        {error}
      </Typography>
    </Box>
  )
}

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.error.main,
    borderRadius: 5,
    padding: theme.spacing(1),
    backgroundColor: alpha(theme.palette.error.light, 0.2),
  },
}))
