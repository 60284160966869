import React from 'react'
import cn from 'classnames'
import { CircularProgress, createStyles, makeStyles, Typography, useTheme } from '@material-ui/core'
import { useNavigate } from 'react-router-dom'
import { useRatesQuery } from '../../../rate/hooks/useRatesQuery'
import { formatRateMonths } from 'app/utils/dates'
import { TariffCard } from './TariffCard'
import { BasicButton } from 'app/components/button/button'
import { BUTTON } from 'app/utils/constants'
import { To } from 'app/router/to'

const priceFormatter = new Intl.NumberFormat('ru-Ru', {
  style: 'currency',
  currency: 'RUB',
  maximumFractionDigits: 0,
})

type Props = {
  className?: string
}

export const Tariffs = ({ className }: Props) => {
  const navigate = useNavigate()
  const theme = useTheme()
  const classes = useStyles()
  const tariffs = useRatesQuery()

  const handleProcessPurchase = () => {
    navigate(To.rate)
  }

  return (
    <div className={cn(classes.root, className)}>
      {tariffs.isLoading && <CircularProgress className={classes.loader} size={40} />}
      {tariffs.isSuccess && (
        <>
          <Typography className={classes.title} variant={'h1'} component={'h2'} align={'center'}>
            Тарифы
          </Typography>
          {tariffs.data.map((tariff) => (
            <TariffCard
              className={classes.tariff}
              name={`Подписка на ${formatRateMonths(tariff.months)}`}
              extraText={tariff.benefit}
              price={priceFormatter.format(tariff.price)}
            />
          ))}
          <BasicButton
            className={classes.button}
            color={theme.palette.pink.main}
            textColor={theme.palette.pink.contrastText}
            title={BUTTON.subscribe}
            fullWidth
            withoutBorder
            onClick={handleProcessPurchase}
          />
        </>
      )}
    </div>
  )
}

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
    },
    loader: {
      padding: theme.spacing(5),
      margin: 'auto',
      width: 40,
      height: 40,
    },
    title: {
      marginBottom: theme.spacing(5),
    },
    tariff: {
      marginBottom: theme.spacing(2),
      '&:last-of-type': {
        marginBottom: 0,
      },
    },
    button: {
      marginTop: 40,
    },
  }),
)
