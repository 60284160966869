import cn from 'classnames'
import config from '../../app/config/config'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import { BasicButton } from '../../app/components/button/button'
import { VerticalLinearStepper } from './Stepper'
import { BUTTON } from '../../app/utils/constants'

type Props = {
  className?: string
}

export const Helper = ({ className }: Props) => {
  const classes = useStyles()
  const theme = useTheme()

  return (
    <div className={cn(classes.restore, className)}>
      <Typography variant={'h3'}>{'Как узнать пароль?'}</Typography>
      <VerticalLinearStepper />
      <BasicButton
        className={classes.button}
        component={'a'}
        variant={'outlined'}
        color={theme.palette.text.accent}
        title={BUTTON.help}
        href={config.supportUri}
      />
    </div>
  )
}

const useStyles = makeStyles(() => ({
  restore: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  button: {
    lineHeight: '20px',
    fontWeight: 500,
    fontSize: '16px',
    letterSpacing: '-0.24px',
  },
}))
