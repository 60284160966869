import { useLocation } from 'react-router-dom'

type LocationState = { from?: Location } | undefined

export function useAuthRedirect(defaultRedirectPathname: string): string
export function useAuthRedirect(): string | undefined
export function useAuthRedirect(defaultRedirectPathname?: string) {
  const location = useLocation()
  const state = location.state as LocationState
  return state?.from?.pathname ?? defaultRedirectPathname
}
