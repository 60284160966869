import { useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { authRepository } from '../../../app/api/authRepository'
import { Unwrap } from '../../../app/types/common'
import { RestError } from '../../../app/types/rest'
import { authKeys } from './authKeys'

type Error = AxiosError<RestError>
type Response = Unwrap<ReturnType<typeof authRepository.me>>

export const useMeQuery = () => {
  return useQuery<Response, Error>(authKeys.me(), authRepository.me)
}
