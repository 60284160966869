import { useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { ratesRepository } from '../../../app/api/ratesRepository'
import { Unwrap } from '../../../app/types/common'
import { RestError } from '../../../app/types/rest'
import { ratesKeys } from './ratesKeys'

type Error = AxiosError<RestError>
type Response = Unwrap<ReturnType<typeof ratesRepository.getRates>>

export const useRatesQuery = () => {
  return useQuery<Response, Error>(ratesKeys.list(), ratesRepository.getRates)
}
