type DeclensionOptions = {
  one: string
  few: string
  many: string
}

export const getDeclension = (count: number, options: DeclensionOptions) => {
  const value = Math.abs(count) % 100
  const number = value % 10
  if (value > 10 && value < 20) return options.many
  if (number > 1 && number < 5) return options.few
  if (number === 1) return options.one
  return options.many
}

export const formatYears = (count: number) => {
  return `${count} ${getDeclension(count, { one: 'год', few: 'года', many: 'лет' })}`
}

export const formatMonths = (count: number) => {
  return `${count} ${getDeclension(count, { one: 'месяц', few: 'месяца', many: 'месяцев' })}`
}
