import { FC } from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { RESTORE_PASSWORD_PAGE } from '../../app/utils/constants'
import { BasicButton } from '../../app/components/button/button'
import { To } from '../../app/router/to'

export const PasswordRecoveryInfoPage: FC = () => {
  const classes = useStyles()
  const theme = useTheme()
  const navigate = useNavigate()

  const [searchParams] = useSearchParams()
  const email = searchParams.get('email') ?? ''

  const navigateToHome = () => navigate(To.main)

  return (
    <div className={classes.root}>
      <Typography className={classes.header} variant={'h1'}>
        {RESTORE_PASSWORD_PAGE.header}
      </Typography>
      <Typography className={classes.text} variant={'body1'}>
        Мы отправили письмо со ссылкой для изменения пароля по адресу{' '}
        <Typography className={classes.email} variant={'inherit'} component={'span'}>
          {email}
        </Typography>{' '}
        (убедитесь, что оно не попало в спам).
      </Typography>
      <BasicButton
        className={classes.button}
        variant={'outlined'}
        color={theme.palette.text.accent}
        title={'Вернуться на главную'}
        onClick={navigateToHome}
      />
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '390px',
    minHeight: '100vh',
    margin: '0 auto',
    padding: theme.spacing(5, 2, 2),
  },
  header: {
    marginBottom: 40,
  },
  text: {
    fontWeight: 500,
    color: theme.palette.text.secondary,
  },
  email: {
    fontWeight: 600,
    color: theme.palette.text.primary,
  },
  button: {
    margin: theme.spacing(2, 'auto', 0),
  },
}))
