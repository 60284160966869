import React from 'react'
import { CssBaseline, ThemeProvider } from '@material-ui/core'
import { QueryClientProvider } from '@tanstack/react-query'
import { uiTheme } from './app/config/theme'
import { MainRouter } from './app/router/router'
import { queryClient } from './app/datasource/remote/rest/queryClient'
import { AuthProvider } from './features/auth/AuthProvider'

export function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={uiTheme}>
        <AuthProvider>
          <CssBaseline />
          <MainRouter />
        </AuthProvider>
      </ThemeProvider>
    </QueryClientProvider>
  )
}
