import React, { FC, useRef } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { CarouselPage } from './components/carouselPage/CarouselPage'
import { FirstPage } from './components/firstPage/FirstPage'
import { AboutPage } from './components/aboutPage/AboutPage'
import { Tariffs } from './components/tariffs/Tariffs'
import { Reviews } from './components/reviews/Reviews'

export const MainPage: FC = () => {
  const classes = useStyles()
  const resultRef = useRef(null)

  return (
    <div className={classes.root}>
      <FirstPage elementRef={resultRef} />
      <div className={classes.container}>
        <CarouselPage className={classes.item} ref={resultRef} />
        <Reviews className={classes.item} />
        <Tariffs className={classes.item} />
        <AboutPage />
      </div>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  container: {
    padding: theme.spacing(8, 2, 5),
  },
  item: {
    marginBottom: theme.spacing(8),
  },
}))
