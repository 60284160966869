import { createTheme } from '@material-ui/core/styles'
import { fontFace } from './font'

export const uiTheme = createTheme({
  palette: {
    blue: {
      main: '#d3e2ff',
      contrastText: '#111111',
    },
    yellow: {
      main: '#fff0dc',
      contrastText: '#111111',
    },
    pink: {
      main: '#efcaff',
      contrastText: '#111111',
    },
    background: {
      default: '#fff',
    },
    text: {
      primary: '#111',
      secondary: 'rgb(44, 45, 46, 0.7)',
      accent: '#7c8fd3',
    },
  },
  typography: {
    fontFamily: 'SF Pro Display',
    h1: {
      fontSize: '2.125rem',
      lineHeight: '2.1875rem',
      fontStyle: 'normal',
      color: '#111111',
      fontWeight: 700,
      letterSpacing: '0.374px',
    },
    h2: {
      fontSize: '20px',
      lineHeight: '24px',
      fontWeight: 700,
      color: '#7C8FD3',
      letterSpacing: '0.38px',
    },
    h3: {
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: '20px',
      lineHeight: '24px',
      letterSpacing: '0.38px',
    },
    h4: {
      fontSize: '1rem',
      lineHeight: '1.375rem',
      fontWeight: 'normal',
      color: '#111111',
    },
    h5: {
      fontSize: '0.875rem',
      lineHeight: '1.375rem',
      fontWeight: 'normal',
      color: '#111111',
    },
    button: {
      color: '#111111',
      fontSize: '1.25rem',
      fontWeight: 700,
      lineHeight: '1.5rem',
      letterSpacing: '0.38px',
      fontStyle: 'normal',
    },
    body1: {
      fontSize: '15px',
      lineHeight: '24px',
      fontStyle: 'normal',
      color: '#2C2D2E',
      fontWeight: 500,
      letterSpacing: '-0.24px',
    },
    body2: {
      fontSize: '15px',
      lineHeight: '24px',
      fontStyle: 'normal',
      color: 'rgba(44, 45, 46, 0.7)',
      fontWeight: 400,
      letterSpacing: '-0.24px',
    },
    caption: {
      fontSize: '11px',
      lineHeight: '14px',
      fontStyle: 'normal',
      color: '#72D7FA',
      fontWeight: 400,
      letterSpacing: '0.066px',
      textDecorationLine: 'underline',
      cursor: 'pointer',
    },
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': fontFace,
        '*, *::before, *::after': {
          boxSizing: 'initial',
        },
      },
    },
    MuiRadio: {
      root: {
        color: '#818C99',
      },
      colorSecondary: {
        '&$checked': {
          color: '#818C99',
        },
      },
    },
  },
})
