import axios, { AxiosInstance, AxiosRequestConfig } from 'axios'
import { stringify } from 'query-string'

import config from '../../../config/config'

class RestClient {
  private client: AxiosInstance

  constructor(config?: AxiosRequestConfig) {
    this.client = axios.create(config)
  }

  public get instance(): AxiosInstance {
    return this.client
  }

  async post<T>(url: string, data: any, config?: AxiosRequestConfig): Promise<T> {
    const response = await this.client.post(url, data, config)
    return response.data
  }

  async get<T>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T> {
    if (data) {
      const parameters = stringify(data)
      url = parameters ? `${url}?${parameters}` : url
    }
    const response = await this.client.get(url, config)
    return response.data
  }

  async put<T>(url: string, data?: any): Promise<T> {
    const response = await this.client.put(url, data)
    return response.data
  }

  async remove<T>(url: string): Promise<T> {
    const response = await this.client.delete(url)
    return response.data
  }
}

const restConfig: AxiosRequestConfig = { baseURL: config.apiUrl }
export const restClient = new RestClient(restConfig)
